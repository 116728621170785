<template>
  <!-- @click.stop="toClash" -->
  <div class="clash_card_wrapper z-1" v-ripple="{ class: 'rightBg--text' }" @click.stop="toClash">
    <!-- <div class="clash_card_team_wrapper1"> -->

    <div class="clash_card_title_wrapper" :class="[battleStatusTitleEnum[info?.battleStatus]?.class]">
      <!-- bg-white rounded-lg px-2 -->
      <div class="d-flex justify-start align-center clash_card_title_left text-xs">
        <div class="mr-1 text-2 md:text-2.5 xl:text-3 text-white">
          {{ battleStatusTitleEnum[info?.battleStatus]?.title }}
        </div>

        <!-- <div class="d-flex justify-start align-center clash_card_title_left">
        <icon v-show="!skeleton" :class="[info?.crazyType == 1 ? 'crazy--text' : '']" className="icon-warBox" name="war">
        </icon>
        <div class="mx-2 round-x">{{ info?.round ? 'x' : '' }}</div>
        <div class="clash_card_round">
          <span v-if="info?.battleProcess != info?.round">
            {{ info?.battleProcess }}/
          </span>
          <span>
            {{ info?.round }}
          </span>
        </div>
      </div> -->
      </div>
      <div class="clash_card_title_right">
        <v-icon v-show="!skeleton" size="0.75rem" class="icon-view" color="black" @click.stop="clickShare">
          {{ icons.mdiOpenInNew }}
        </v-icon>
      </div>
    </div>
    <div :id="`war_box_area_${selfDomId}_${info?.id || 0}_parent`">
      <div :group="true" class="war_box_list_wrapper" :id="`war_box_area_${selfDomId}_${info?.id || 0}`">
        
        <div :key="`war_box_process_img_${selfDomId}_${info?.id || 0}`" class="icon-directed"
          :id="`war_box_process_img_${selfDomId}_${info?.id || 0}`" :style="{ transform: 'translateX(' + toShift + 'px)' }">
          <svg v-for="(item, i) in 2" :key="i" class="icon-directed-triangle" viewBox="-50 -50 300 300">
            <polygon :class="[info?.crazyType == 1 ? 'triangle-crazy' : 'triangle']" stroke-linejoin="round"
              points="100,0 0,200 200,200" />
          </svg>
        </div>

        <!-- info?.battleProcess-1，info?.battleProcess，info?.battleProcess+1 -->

        <div v-for="(item, index) in boxFlatList" class="war_box_wrapper"
          :key="`war_box_${selfDomId}_${info?.id || 0}_${item.round}`"
          :id="`war_box_${selfDomId}_${info?.id || 0}_${item.round}`">

          <div class="war_goods">
            <v-img class="war_goods_img" :alt="`clash-picture-${item.round}`"
              :src="$S3Utils.resizeBoxPictureLazy(item.image || '')" />
            <!-- <div class="absolute bottom-2 right-2 op-20">
              {{ item.round }}
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="clash_card_team_user_wrapper">
      <template v-for="(teamList, teamName) in teamMap">
        <div class="battles_box_war" :key="teamName">
          <template v-if="!loading">
            <div class="war_avatar_area">
              <template v-for="(item, index) in battleTypeEnum.number">
                <div class="war_avatar flex allcenter" :key="index" :class="[
                  teamList[index] && teamList[index]?.avatar
                    ? info?.crazyType == 1
                      ? 'crazy'
                      : 'success'
                    : 'avatar_no',
                  battleTypeEnum.number == 2 ? 'war_two' : '',
                ]">
                  <div class="flex allcenter battle_icon_join_wrapper" :class="[info?.privateType == 1 ? '' : 'btn']"
                    v-if="!skeleton && (!teamList[index] || !teamList[index]?.avatar)">
                    <v-icon class="absolute" size="1.5rem" :color="info?.crazyType == 1 ? 'crazy' : 'success'">
                      {{ info?.privateType == 0 ? icons.mdiPlusThick : icons.mdiLock }}
                    </v-icon>
                  </div>
                  <div class="w-full h-full flex allcenter" v-if="teamList[index] && teamList[index]?.avatar">
                    <v-img class="icon-default-avatar" :alt="teamList[index]?.nickname"
                      :src="$S3Utils.toWebp(teamList[index]?.avatar)">
                    </v-img>
                    <!-- <img class="war-level flex allcenter" :class="info?.crazyType == 1 ? 'crazy' : 'success'"
                      :src="$S3Utils.getLevel(teamList[index].level)" :alt="teamList[index].level" /> -->
                  </div>
                </div>
              </template>

              <!-- <div class="war_situation" v-if="teamList.length != battleTypeEnum.number">
                    PLAYERS
                    {{ teamList.length }}/{{ battleTypeEnum.number }}
                  </div> -->
            </div>
            <!--					<div class="war_sum" v-if="teamList.length == battleTypeEnum.number" >-->
            <!--						${{ info.battleTeamMap.teamAWinItemsTotalValue || 0 }}-->
            <!--					</div>-->
          </template>
        </div>
      </template>
    </div>

    <div class="clash_card_footer_wrapper">
      <div class="clash_card_price flex flex-col flex-items-start text-2 md:text-2.5 xl:text-3">
        <div>{{ info?.round }} ROUNDS</div>
        <div v-if="info?.totalBoxPrice" :class="[info?.battleStatus == 2?'winner':'']"
        >${{ getClashPrice | matterPriceDecimal }}</div>
      </div>
      <div>
        <v-btn depressed small :loading="loading" @click.stop="toClash" 
          class="pa-2" color="black">
          <!-- {{ info?.battleStatus < 1 ? 'JOIN' : 'WATCH' }}  -->
          <span class="text-2 xl:text-3">
            {{ battleStatusEnum[info?.battleStatus] }}
          </span>
        </v-btn>
      </div>
    </div>
    <!-- </div> -->

    <!-- <component
      v-if="showType"
      :type="showType"
      :info="info"
      @close="closeDialog"
      :is="lazyComponents.battlesDialog"
    /> -->
  </div>
</template>

<script>
import { BATTLE_TYPE_ENUM } from "@/utils/constants";
import { mdiPlusThick, mdiLock, mdiOpenInNew, mdiTriangle } from "@mdi/js";
import { random } from "@/utils/functions";
let isMouseDown = false; // 鼠标是否被按下
let startX = 0; // 鼠标按下时的坐标
let scrollLeft = 0; // div容器滚动的距离
let mouseTimer = 0; // div容器滚动的距离

export default {
  components: {},
  data () {
    return {
      icons: {
        mdiPlusThick,
        mdiLock,
        mdiOpenInNew,
        mdiTriangle,
      },
      toShift: 0, //指针图片当前的位置 单位px
      initShirt: 0, //指针图片初始的位置 单位px
      initWidth: 0, // 一个盲盒的宽度
      showType: 0, //选择展示的弹窗 1加入队伍

      teamMap: {
        A: [],
        B: [],
      }, // team
      battleTypeEnum: {
        number: 0,
        totalNumber: 0,
      },
      // lazyComponents: {
      // battlesDialog: null,
      // },
      selfDomId: random(1, 9999),
      boxFlatList: [],

      battleStatusTitleEnum: {
        //
        0: { title: "WAITING", class: "success", textClass: "text-red-500" },
        1: { title: "CLASHING", class: "bg-yellow-500", textClass: "text-yellow-500" },
        2: { title: "FINISHED", class: "bg-green-500", textClass: "text-green-500" },
        3: { title: "CLOSED", class: "bg-gray-500", textClass: "text-gray-500" },
      },

      battleStatusEnum: {
        0: "JOIN",
        1: "WATCH",
        2: "WATCH",
        3: "CLOSED",
      },
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    refreshShift: {
      default: false,
    },
    skeleton: {
      default: false,
    },
    info: {
      type: Object,
      default: () => { },
    },
  },
  watch: {
    info: {
      handler (val, oval) {
        if (val?.boxList?.length != 0) {

          let _boxFlatList = val?.boxList?.map((box, index) => {
            return Array.from({ length: box.num }, () => {
              return {
                image: box.picture,
              };
            });
          }).flat(2).map((item, i) => {
            return {
              ...item,
              round: i + 1,
            };
          }) || []
          _boxFlatList.unshift({ round: 0 });
          _boxFlatList.push({ round: -1 });
          this.boxFlatList = _boxFlatList;
        }
        this.teamDetail();
        this.computedShift();
        // if (val?.battleProcess != oval?.battleProcess) {
        //   this.computedShift();
        // }
      },
      immediate: true,
      deep: true, // 深度监听
    },
    refreshShift: {
      handler (val, oval) {
        if (val) {
          // console.log("refreshShift", val);
          this.computedShift();
        }
      },
      immediate: true,
      deep: true, // 深度监听
    },
  },
  computed: {
    device () {
      return this.$store.state.user.device;
    },
    // 根据结束时间显示金额
    getClashPrice ({ info }) {
      let price = 0;
      if (info?.battleStatus == 2) {
        price = info?.teamAWinItemsTotalValue + this.info?.teamBWinItemsTotalValue;
      } else {
        price = info?.totalBoxPrice;
      }
      if (price) {
        return price / 100;
      }
      return 0;
    },
    getBoxList ({ info }) {


      if (this.info && this.info?.boxList?.length > 0) {

        let boxFlatList = this.info?.boxList?.map((box, index) => {
          return Array.from({ length: box.num }, () => {
            return {
              image: box.picture,
            };
          });
        })
          .flat(2)
          .map((item, i) => {
            return {
              ...item,
              round: i + 1,
            };
          });
        //console.log("count",info?.battleProcess)
        let _battleProcess = info?.battleProcess || 1;
        let _index = _battleProcess - 1;
        let _round_1 = boxFlatList[_index - 1] || { round: _battleProcess - 1 };
        let _round_2 = boxFlatList[_index] || { round: _battleProcess };
        let _round_3 = boxFlatList[_index + 1] || { round: _battleProcess + 1 };
        return [_round_1, _round_2, _round_3];
      } else {
        return [{ round: 1 }, { round: 2 }, { round: 3 }];
      }
    },
  },
  created () { },
  mounted () {
    this.$nextTick(() => {

      window.addEventListener("resize", this.computedShift);
      this.initBoxAreaScroll();

    });
  },
  methods: {
    clickShare () {
      this.$emit("share");
    },
    jumpBattleProcessView (leftWidth = 0) {
      const scrollContainer = document.getElementById(
        `war_box_area_${this.selfDomId}_${this.info?.id || 0}`
      );
      if (scrollContainer) {
        //console.log("jumpBattleProcessView", scrollContainer.offsetWidth, this.toShift - leftWidth)
        scrollContainer.scrollTo({
          left: this.toShift - leftWidth,
          behavior: "smooth",
        });
        
      }
    },
    // 设置盒子区域可拖动
    initBoxAreaScroll () {
      setTimeout(() => {
        const scrollContainer = document.getElementById(
          `war_box_area_${this.selfDomId}_${this.info?.id || 0}`
        );
        if (scrollContainer) {
          //scrollContainer.style.pointerEvents = "none";
          //scrollContainer.style.pointerEvents = null;

          scrollContainer.addEventListener("mousedown", (e) => {
            isMouseDown = true;
            startX = e.pageX - scrollContainer.offsetLeft;
            scrollLeft = scrollContainer.scrollLeft;
            e.preventDefault();
          });

          scrollContainer.addEventListener("mousemove", (e) => {
            if (!isMouseDown) return;
            e.preventDefault();
            const x = e.pageX - scrollContainer.offsetLeft;
            const walk = x - startX;
            scrollContainer.scrollLeft = scrollLeft - walk;

            clearTimeout(mouseTimer);
            mouseTimer = setTimeout(function () {
              isMouseDown = false;
            }, 300);
          });
          scrollContainer.addEventListener("click", function (e) {
            if (isMouseDown) {
              e.preventDefault();
              e.stopPropagation();
            }
          });
          scrollContainer.addEventListener("mouseup", (e) => {
            setTimeout(() => (isMouseDown = false), 50);
          });
        }
      }, 500);
    },
    // 设置团队加入情况
    teamDetail () {
      // number teamNumber
      // totalNumber number*2
      this.battleTypeEnum = BATTLE_TYPE_ENUM[Number(this.info?.battleType || 1)];

      if (this.info?.teamList) {
        let teamList = this.info?.teamList || [];
        let teamMap = {
          A: [],
          B: [],
        };
        teamList.forEach((item) => {
          teamMap[item.teamName].push(item);
        });
        this.teamMap = teamMap;
      }
    },
    toClash () {
      // if (isMouseDown) return;
      let info = this.info;
      if (info?.id) {
        let query = {
          id: info?.id,
        };

        let _end_flag = info?.endTime <= Date.now();
        // 结束的
        if (_end_flag) {
          query.replay = 0
        }
        // 未结束
        else {
          query.round = info.battleProcess
        }

        this.$router.push({
          name: "detail",
          query,
        });
      }
    },

    // 加入队伍弹窗
    // joinTeam() {
    // 前置条件判断
    // if (!this.$store.state.user.isLogin) {
    //   this.$emit("close");
    //   this.$bus.$emit("showLogin0", { type: "login", open: true });
    //   return;
    // }
    // if (this.info?.privateType == 1) {
    //   this.$awn.warning("You can't join a private battle.");
    //   return;
    // }
    // this.showType = 1;
    // if (!this.lazyComponents.battlesDialog) {
    //   this.lazyComponents.battlesDialog = () =>
    //     import("../components/battlesDialog.vue");
    // }
    // },

    // 关闭弹窗
    closeDialog () {
      this.showType = 0;
    },
    computedShift () {
      setTimeout(() => {
        
        const element = document.getElementById(`war_box_${this.selfDomId}_${this.info?.id}_${this.info?.battleProcess || 1}`)

        if (element) {
          //console.log("computedShift", element.offsetLeft, element.offsetWidth, document.getElementById(`war_box_process_img_${this.selfDomId}_${this.info?.id}`)?.offsetWidth/2)
          this.toShift = element.offsetLeft + element.offsetWidth / 2 - document.getElementById(`war_box_process_img_${this.selfDomId}_${this.info?.id}`).offsetWidth / 2;
          this.jumpBattleProcessView(element.offsetWidth)
        }
      }, 600);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-warBox {
  width: 1.4rem;
  color: var(--v-black-base);
}
.clash_card_wrapper {
  user-select: none;
  width: 100%;

  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  background: var(--v-battlebox_bg-base);

  border-radius: 12px;

  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;

  .clash_card_title_wrapper {
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    height: 3rem;
    justify-content: space-between;
    border-radius: 0.75rem 0.75rem 0 0;
    font-weight: bold;
    .clash_card_title_left {
      height: 1.4rem;
      //aspect-ratio: 1;
      //background: #FFFFFF;
      //border-radius: 6px;
      //padding: 0 24px;
    }
    .round-x {
      font-size: 12px;
      //color: #999;
    }
    .clash_card_round {
      display: inline-block;

      font-size: 14px;
      //border-radius: 50%;

      //text-align: center;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      //width: 25px;
      //height: 25px;
    }
    .clash_card_title_right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .clash_card_footer_wrapper {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.75rem;
    height: 3rem;
    background: var(--v-dialog-base);
    border-radius: 0 0 12px 12px;
  }
  .clash_card_price {
    font-weight: bold;
    min-height: 1rem;
  }
  .clash_card_team_wrapper {
    height: 100%;
  }
  .clash_card_team_user_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }

  .battles_box_war {
    //width: 196px;
    width: calc(50% - 6px);
    padding: 0.5rem 0;
    background: var(--v-battleboxwar_bg-base);
    border-radius: 8px;

    .war_avatar_area {
      // height: 252px;
      height: 100%;
      display: flex;
      //flex-direction: column;
      align-items: center;
      justify-content: space-around;
      transition: all 1s ease;
      position: relative;

      .war_avatar {
        width: 35%;
        aspect-ratio: 1;
        flex: inherit;
        position: relative;
        border-radius: 100%;

        .battle_icon_join_wrapper {
          height: 90%;
          width: 90%;
          background: var(--v-battleboxwar_bg-base);
          border-radius: 100%;
        }
        .battle-icon-avatar-area {
          border-radius: 100%;
          background-color: var(--v-battle_avatar_bg-base);
          display: flex;
          align-items: flex-end;
          justify-content: center;
          overflow: hidden;

          .battle-icon-avatar {
            width: 70px;
            height: 70px;
            flex: none;
            color: var(--v-detail_avatar-base);

            ::v-deep .v-responsive__content {
              width: auto !important;
            }
          }
        }

        .icon-default-avatar {
          border-radius: 100%;
          flex: none;

          ::v-deep .v-responsive__content {
            width: auto !important;
          }
        }
      }

      .avatar_have {
        background-color: var(--v-success-base);
      }

      .avatar_no {
        //background-color: var(--v-battlewar_goods-base);
      }

      .war_two {
      }

      .war_two:last-child {
      }

      .war-level {
        width: 1rem;
        height: 1rem;
        padding: 1px;
        background: var(--v-success-base);
        border: 1px solid #ffffff !important;
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 100%;

        font-size: 0.75rem;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .war_situation {
      font-size: 16px;
      font-weight: 500;
      color: #999999;
      line-height: 17px;
      position: absolute;
      bottom: 0.625rem;
    }

    .war_sum {
      font-size: 16px;
      font-weight: 500;
      color: var(--v-battlewar_sum-base);
      line-height: 17px;
    }
  }

  .war_money {
    font-size: 14px;
    font-weight: 600;
    color: var(--v-black-base);
    display: flex;
    align-items: center;
    padding: 12px 0 7px;

    span {
      display: inline-block;
    }

    .war_money_line {
      flex: 1;
      height: 1px;
      background-color: #ececec;
    }

    .icon-hot {
      width: 13px;
      height: 18px;
      margin-left: 22px;
    }

    .margin-span1 {
      margin: 0 17px;
    }

    .margin-span2 {
      margin: 0 17px 0 9px;
    }
  }

  /* 隐藏滚动条 */
  .war_box_list_wrapper::-webkit-scrollbar {
    display: none;
  }

  .war_box_list_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: scroll;
    //width: 50rem;

    //cursor: grab;
    //pointer-events: none;
    //overflow: auto;
    //-webkit-overflow-scrolling: touch;


    .war_box_wrapper {
      height: 8rem;
      width: 8rem;
      aspect-ratio: 1;
      background-color: transparent;
      position: relative;
      margin-right: 0%;
      padding: .2rem;
      box-sizing: border-box;
      

      .war_goods {
        // width: 92px;
        width: 100%;
        height: 100%;
        background-color: var(--v-battlewar_goods-base);

        font-size: 20px;
        font-weight: 500;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none !important;
        border-radius: 8px;

        .war_goods_img {
          width: 80%;
          flex: none !important;
        }
      }
    }

    .war_box_wrapper:nth-child(2),
    .war_box_wrapper:last-child {
      width: 4rem;
      visibility: hidden;
    }

    .war_box_wrapper:last-child {
      margin-right: 0px;
    }

    .icon-directed {
      width: fit-content;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      z-index: 1;
      transition: 1s ease;
      //align-items: center;
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;

      .triangle {
        fill: var(--v-primary-base);
        stroke: var(--v-primary-base);
        stroke-width: 60;
      }
      .triangle-crazy {
        fill: var(--v-crazy-base);
        stroke: var(--v-crazy-base);
        stroke-width: 60;
      }

      .icon-directed-triangle {
        width: 0.625rem;
        height: 0.625rem;
        &:last-child {
          transform: rotate(180deg);
          will-change: transform;
        }
      }
    }
  }
}

.clash_card_wrapper:nth-child(3n) {
  margin-right: 0px;
}

@media (max-width: 1024px) {
  .clash_card_wrapper {
    width: 100% !important;
    box-sizing: border-box;
    height: 15rem;
    .clash_card_title_wrapper {
      height: 2rem;
    }
    .clash_card_team_user_wrapper {
      margin: 0;
      padding: 6px;
    }
    .clash_card_footer_wrapper {
      height: 2.5rem;
    }
    .battles_box_war {
      //height: 6rem;
      .war_avatar {
        .battle-icon-avatar-area {
          .battle-icon-avatar {
          }
        }

        .icon-default-avatar {
        }
      }

      .war_sum {
        font-size: 12px;
      }

      .war_situation {
        font-size: 12px;
      }
    }

    .war_money {
      //margin-top: 12px;
      //font-size: 12px;
    }
    .war_box_list_wrapper {
      .war_box_wrapper {
        width: 5rem;
        height: 5.8rem;
        .war_goods {
          .war_goods_img {

          }
        }

      }
      .icon-directed {
        .icon-directed-triangle {
          width: 0.45rem;
          height: 0.45rem;
        }
      }
    }
  }

  .war-level {
    width: 0.8rem !important;
    height: 0.8rem !important;
  }
}
</style>
