/**
 * 获取浏览器版本
 */
function getServerVersion() {
    return new Promise((resolve, reject) => {
        fetch(`${import.meta.env.BASE_URL}version.json?t=${new Date().getTime()}`)
            .then(response => {
                if (response.ok) {
                    return resolve(response.json());
                }
                return resolve(null)
            })
            .catch(error => {
                console.error('Error:', error)
                reject(error)
            });

    })
}

function clearSWCache() {
    caches.keys().then(keys => {
        keys.map(async key => {
            // != cloudfront
            if (!key.includes("cloudfront")) {
                await caches.delete(key)
                console.log("caches.delete", key)
            }
        })
    })

}

export function checkAppUpdate() {
    return new Promise((resolve, reject) => {
        getServerVersion().then(data => {
            let {v: prodVersion, msg} = data ?? {}
            const localVersion = window?.__APP_VERSION__ || ''
            console.log({localVersion, prodVersion})
            if (localVersion && prodVersion && Number(prodVersion) > Number(localVersion)) {
                clearSWCache()
                console.info(`App Update! Need refresh! v${prodVersion}, ${msg}`)
                // msg!='' => notice
                return resolve({
                    msg,
                    update: true,
                })
            } else {
                return resolve({
                    msg:'',
                    update: false,
                })
            }
        })
    }).catch(error => console.error('App Update Error:', error));


}
