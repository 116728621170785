import Vue from 'vue'
import App from './App.vue'
import router from './router'
//聊天相关
import store from './store'

import './utils/rem.js'
// main.ts
import 'virtual:uno.css'

import vuetify from '@/plugins/vuetify' // path to vuetify export

import ModelNav from '@/components/ModelNav.vue';
// import './plugins/stats.js'
import './plugins/gt.js'

// import './plugins/sentry.js'
import Clipboard from "v-clipboard";
Vue.use(Clipboard)

// 编程弹窗
import BoxDialog from '@/components/vuetify/BoxDialog.js'
Vue.use(BoxDialog,{vuetify});
Vue.component('ModelNav',ModelNav);

import './plugins/breakpoints.js'

import ws0 from './plugins/ws/ws'
Vue.use(ws0)
import {priceFormat} from '@/utils/functions'


import S3Utils from '@/utils/awsS3Utils'
Vue.prototype.$S3Utils = S3Utils;

import './registerServiceWorker'
// import './unregisterServiceWorker'

import './core/directives'

import "@/assets/css/public.scss"


import './icons/index'

import VueLazyComponent from '@xunlei/vue-lazy-component'
Vue.use(VueLazyComponent)

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1,
  // error: require('./assets/img/error.jpg'),
  // loading: require('./assets/img/homePage_top.jpg'),
  attempt: 2,
})


import '@/plugins/awn' // awn 弹窗
import {getAssetsImagesFile, getAssetsFile} from "@/utils/pubUse";
Vue.prototype.$getAssetsImagesFile = getAssetsImagesFile;
Vue.prototype.$getAssetsFile = getAssetsFile;


//保留两位小数
Vue.filter('matterPriceDecimal', priceFormat)
Vue.filter('priceFormat', priceFormat)

//保留两位小数
//时间戳转换

//时间格式化
Vue.filter('formatTime', (time) => {
  const monthEnglish = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec"];
  let month = monthEnglish[new Date(new Date(time)).getMonth()];
  let day = new Date(new Date(time)).getDate();
  let year = new Date(new Date(time)).getFullYear();
  let h = new Date(new Date(time)).getHours();
  let m = new Date(new Date(time)).getMinutes();
  let s = new Date(new Date(time)).getSeconds();
  let apm = 'AM';
  if (h > 12) {
    h -= 12;
    apm = 'PM'
  }
  if (h < 10) h = '0' + h;
  if (m < 10) m = '0' + m;
  if (s < 10) s = '0' + s;
  return (month + ' ' + day + ',' + year + ',' + h + ':' + m + ':' + s + ' ' + apm);
})
Vue.config.productionTip = false


import VueHead from 'vue-head'
Vue.use(VueHead)

const Instance = new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this //已绑定updataUserinfo更新函数，改变用户信息或状态时可触发
  },
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
export default Instance
