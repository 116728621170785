<template>
  <div class="box-detail-wrapper">

    <v-dialog content-class="v-dialog-right" v-model="value" scrollable  min-height="90%"
      @click:outside="close">
      <v-card :elevation="0" rounded="0" color="dialog">
        <v-card-title class="important-px-6 important-py-4 important-font-bold">
          <div class="d-flex justify-space-between align-center w-full">
            <div>
              <span>ITEM DETAIL</span>
            </div>
            <icon class-name="pointer v-dialog-close" name="dialog-close" width="25px" height="25px" @click="close">
            </icon>


          </div>
        </v-card-title>



        <v-card-text class="font-weight-bold black--text px-0 py-4  white px-6 scrollBarHidden">

          <div class="box-detail-item-wrapper">


            <div mt-4 class=" text-center w-full  flex justify-start items-center">
              <div>
                <v-img :alt="goods?.picture" 
                  :lazy-src="$S3Utils.resizeBoxPictureLazy(goods?.picture)"
                  :src="goods?.picture"
                   class="w-50 max-h-400px" contain>
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular 
                          :size="40"
                          :width="6" indeterminate 
                          color="success lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>
            
            <div class="item-wrapper" mt-4>
              <div class="item-title black--text">{{ goods?.title }}</div>
            </div>

            <v-divider class="my-4"></v-divider>
            
            <div space-y-4>
              <div class="">
                <div class="box-detail-title pb-1">DESCRIPTION</div>
                <div class="box-detail-content text-3.5 black--text" v-html="goods?.description"></div>
              </div>
            </div>

            <div class="pt-4" v-if="goodsRecord?.unboxingTotalNum">
              <div class="box-detail-title pb-1">POPULATION</div>
              <div class="box-detail-content text-3.5 black--text">
                {{ goodsRecord?.unboxingTotalNum || '-' }}
              </div>
            </div>

            <div class="pt-4" v-if="goods?.goodsBoxList?.length > 0">
              <div class="box-detail-title pb-1">BOXES</div>
              <div class="box-detail-content ">
                <div class="box-info mb-2 elevation-2 hover-scale-sm" @click="clickBoxEvent(item)" v-for="(item, i) in goods.goodsBoxList" :key="i">
                  <div class="box-img-area">
                    <v-img  class="picture" :lazy-src="$S3Utils.resizeBoxPictureLazy(item.picture)"
                      :src="$S3Utils.resizeBoxPicture(item.picture)" :alt="item?.title ?? item.picture" width="100%"
                      height="70" contain></v-img>
                  </div>
                  <div class="box-detail-info">
                    <div class="box-name">
                      {{ item.title }}
                    </div>
                    <div class="box-result">
                      {{ item.outcomeStart }} - {{ item.outcomeEnd }}
                    </div>
                    <div class="box-price">
                      <span class="font-weight-bold">{{item.odds}}</span>% ODDS
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </v-card-text>

        <v-card-actions class="important-px-6 important-py-4 ">
          <span class="success py-1 px-2 rounded"> ${{ (goods?.price || 0) / 100 | matterPriceDecimal }}</span>
        </v-card-actions>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>

// import { getRecordUnboxingCount } from "@/api/userRecord"
import { getGoodsInfo, getGoodsInfoBoxList } from "@/api/product_box.js";

export default {
  name: "BoxDetailHover",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    goodsId: {
      type: [Number, String],
    },
    goodsInfo: {
      type: Object,
      default: () => {

      },
    },
  },
  watch: {
    value: {
      handler (val) {
        if (val) {
          // this.getRecordUnboxingCount();
          if (this.goodsInfo) {
            Object.assign(this.goods, this.goodsInfo)
          }
          this.getInfo()
        }
      },
      deep: true, // 深度监听
      immediate: true,
    },
  },
  components: {

  },
  data () {
    return {
      icons: {},
      loading: false,
      goods: {},
      goodsRecord: {},
    };
  },
  created () {

  },
  mounted () {
  },
  computed: {

  },
  methods: {
    clickBoxEvent (item) {
      if(item?.boxCode) {
        this.$router.push(`/box/${item.boxCode}`);
      } else {
        this.$router.push(`/boxinfo/${item.boxId}`);
      }
    },
    async getInfo () {
      getGoodsInfo({
        goodsId: this.goodsId,
      }).then(res => {
        let data = res.data
        data.goodsBoxList = data?.goodsBoxList?.sort(this.compare("odds")) || [];
        this.goods = res.data
      })
    },
    async getInfoBoxList () {
      getGoodsInfoBoxList({
        goodsId: this.goodsId,
      }).then(res => {
        this.goods.goodsBoxList = res.data?.sort(this.compare("odds")) || [];
      })
    },
    // getRecordUnboxingCount () {
    //   getRecordUnboxingCount({
    //     goodsId: this.goodsId,
    //   }).then(res => {
    //     this.goodsRecord = res.data;
    //   })
    // },
    close () {
      this.$emit("input", false);
    },
    //排序函数
    compare (property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
      };
    },

  },
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s;
}

.v-enter {
  transform: translateY(100%);
}

.v-enter-to {
  transform: translateY(0);
}

.v-leave-to {
  transform: translateY(100%);
}


.box-detail-item-wrapper {
  width: 100%;
  
  color: #999;
  font-size: 12px;
  .item-wrapper {
    font-weight: 700;
    .item-title {
      font-size: 24px;
      line-height: 1.5;
    }
  }
  .box-detail-title {
  }
  .box-detail-content {
    line-height: 1.2;
  }
  .box-detail-max {
    font-size: 24px;
    font-weight: bold;
  }
  
}


.box-info {
  transition: all 0.5s;
  //width: 100%;
  height: 90PX;
  display: flex;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
  //margin-bottom: 10px;
  border-radius: 10px;
  background-color: var(--v-detail_bg1-base);

  .box-img-area {
    width: 38%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .picture {
      height: 100%;
    }

  }

  .box-detail-info {

    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 62%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px;
    text-align: left;

    .box-name {
      color: var(--v-black-base);
      font-weight: 700;
    }
    .box-result {
      padding: 8px 0;
    }

    .box-price {
      color: #3d9d43;
    }
  }
}

</style>
