<template>
  <div
    class="emailBox cflex rcenter"
    :class="{ dmailBox_model: device !== 'PC' }"
    @mousewheel.prevent=""
  >
    <div class="content flex">
      <div v-if="emailList.length > 0"></div>
      <div v-else-if="emailList.length <= 0 && device == 'PC'" class="default">
        No Messages yet
      </div>
      <div
        v-else
        class="default flex allcenter"
        style="margin: 0"
        @click="$emit('close')"
      >
        <span>No Messages yet</span>
      </div>
    </div>
    <v-btn class="more" @click="$emit('close')">{{ emailList.length>0?'View All':'Close' }}</v-btn>
  </div>
</template>

<script>
export default {
  name: "emailBox",
  props: ["device"],
  data() {
    return {
      emailList: [],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.emailBox {
  width: 304px;
  height: 405px;
  padding: 21px;
  box-sizing: border-box;
  background-color: var(--v-white_night-base);
  position: fixed;
  top: 53px;
  right: 24px;
  z-index: 1500;
  .content {
    background-color: transparent;
    font-size: 15px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 16px;
    flex: 1 !important;
    .default {
      width: 100%;
      height: 100%;
      font-size: 16px;
      color: #999999;
      margin-top: 80px;
    }
  }
  .more {
    width: 100%;
    padding: 9px 0;
    background-color: #f0f0f0;
    text-align: center;
    font-size: 16px;
    color: #999999;
    font-weight: 700;
  }
}
.dmailBox_model {
  top: $header-menu-height;
  left: 0;
  width: 100%;
  height: calc(100% - $header-menu-height);
}
</style>
