import store from '@/store'
import { debounce } from 'lodash-es'

function setDevice (width) {
    let _device = 'PC'
    if (width < 750) {
        _device = 'mobile'
    }
    store.dispatch("user/setDeviceType", _device);
    return _device
}

const deviceData = [
    [0, 640, ()=>16, 'mobile'],
    [640, 767, ()=>14, 'mobile'],
    // IPAD
    [767, 1024, ()=>14, 'PC'],
    [1024, 99999, (width)=>{return width/100}, 'PC'],
]
export const setDomFontSize0 = () => {
    let width = document.documentElement.clientWidth || document.body.clientWidth;

    let device = deviceData.find(l => {
        if (width > l[0] && width <= l[1]) {
            return l
        }
    }) || deviceData[deviceData.length-1]
    // console.log({width,...device,},`${device[2](width)}px`)
    store.dispatch("user/setDeviceType", device[3]);

    (document.getElementsByTagName('html')[0].style)['font-size'] = `${device[2](width)}px`;

    store.dispatch("appData/setRootValue", device[2](width));



}


setDomFontSize0()
let setDomFontSizeDebounce = debounce(setDomFontSize0, 400)
window.addEventListener('resize', setDomFontSizeDebounce); // 浏览器加入收缩监听防抖，重新计算rem配置

