<template>
    <div>
        <v-dialog width="50rem" class="r-panel-dialog " v-model="showDepositDialog" persistent>
            <v-card class=" r-panel-card" :elevation="0" rounded="0" color="dialog">
                <div class="d-flex r-panel scrollBar" id="r-panel">
                    <div class="r-panel-left md:(pt-8 px-2)" py-4>

                        <div class="">

                            <div class="r-method-wrapper">
                                <div class="r-panel-title" text-4 pl-3>DEPOSIT</div>

                                <div class="r-method-item-wrapper"  md:pt-4>
                                    <!-- <div class="r-panel-title-sub r-method-item-title">Credit card</div> -->
                                    <div class="r-method-item-method" @click="selectPayType('stripe')"
                                        :class="[getPayTypeActiveClass('stripe')]">

                                        <div class="d-flex align-center r-method-item-method-title-wrapper">
                                            <img class="r-method-item-method-title-icon"
                                                :src="$getAssetsImagesFile('deposit/stripe.png')" alt="onlibox">
                                            <div class="r-method-item-method-title" text-3 md:text-3.5>Stripe</div>
                                        </div>
                                        <div class="r-method-item-method-title-disabled" text-2.5>maintenance</div>
                                    </div>
                                    <div class="r-method-item-method" @click="selectPayType('paypal')"
                                        :class="[getPayTypeActiveClass('paypal')]">

                                        <div class="d-flex align-center r-method-item-method-title-wrapper">
                                            <img class="r-method-item-method-title-icon"
                                                :src="$getAssetsImagesFile('deposit/paypal.png')" alt="onlibox">
                                            <div class="r-method-item-method-title" text-3 md:text-3.5>Paypal</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="r-method-item-wrapper" pt-2 md:pt-4>
                                    <!-- <div class="r-panel-title-sub r-method-item-title">Crypto</div> -->
                                    <div class="r-method-item-method" @click="selectPayType('bsc')"
                                        :class="[getPayTypeActiveClass('bsc')]">

                                        <div class="d-flex align-center r-method-item-method-title-wrapper">
                                            <img class="r-method-item-method-title-icon"
                                                :src="$getAssetsImagesFile('deposit/bnb.png')" alt="onlibox">
                                            <div class="r-method-item-method-title" text-3 md:text-3.5>BSC</div>
                                        </div>
                                    </div>

                                    <div class="r-method-item-method" @click="selectPayType('eth')"
                                        :class="[getPayTypeActiveClass('eth')]">

                                        <div class="d-flex align-center r-method-item-method-title-wrapper">
                                            <img class="r-method-item-method-title-icon"
                                                :src="$getAssetsImagesFile('deposit/eth.png')" alt="onlibox">
                                            <div class="r-method-item-method-title" text-3 md:text-3.5>ETH</div>
                                        </div>
                                        <div class="r-method-item-method-title-disabled" text-2.5>maintenance</div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="">

                            <div class="r-method-wrapper mt-8">
                                <div class="r-panel-title" text-4 pl-3>WITHDRAW</div>

                                <div class="r-method-item-wrapper" md:pt-4>
                                    <!-- <div class="r-panel-title-sub r-method-item-title">Crypto</div> -->
                                    <div class="r-method-item-method " @click="selectPayType('withdraw-bsc')"
                                        :class="[getPayTypeActiveClass('withdraw-bsc')]">
                                        <div class="d-flex align-center r-method-item-method-title-wrapper">
                                            <img class="r-method-item-method-title-icon"
                                                :src="$getAssetsImagesFile('deposit/bnb.png')" alt="onlibox"></img>
                                            <div class="r-method-item-method-title" text-3 md:text-3.5>BSC</div>
                                        </div>
                                        <div class="r-method-item-method-title-disabled" text-2.5>maintenance</div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class=" r-panel-right md:(pt-8 px-8)" p-4 pr-2>

                        <v-tabs-items v-model="payHandlerSwiperTab" :touchless="true">
                            <v-tab-item value="tab-0">

                                <div class="">

                                    <div class="r-method-wrapper">
                                        <div class="d-flex justify-space-between align-center">
                                            <div class="r-panel-title" pl-3>
                                                {{ payTypeMethodInfoEnum[activePayType].name }}
                                            </div>
                                            <icon class-name="pointer v-dialog-close" name="dialog-close" width="25px"
                                                height="25px" @click="showDepositDialog = false">
                                            </icon>
                                        </div>

                                        <div class="pt-6 r-method-item-wrapper"
                                            v-if="payTypeMethodInfoEnum[activePayType]?.list.length > 0">
                                            <div class="r-panel-title-sub r-method-item-title">Coin</div>
                                            <div class="r-method-item-child-wrapper r-method-item-child-wrapper-grid mt-2">
                                                <div class="r-method-item-child-item elevation-1 pointer"
                                                    :class="[selectCryptoCode == item.code ? 'r-method-item-child-item-active' : '']"
                                                    @click="clickCoin(item)"
                                                    v-for="(item, i ) in payTypeMethodInfoEnum[activePayType]?.list"
                                                    :key="i">
                                                    <img class="r-method-item-child-item-icon"
                                                        :src="$getAssetsImagesFile(`deposit/${item.icon}`)" alt="onlibox">
                                                    <div class="r-method-item-child-item-title">
                                                        {{ item.name }}
                                                    </div>
                                                    <div class="coin-disabled" v-if="!item.active">maintenance</div>

                                                </div>

                                            </div>


                                        </div>

                                        <div class="pt-4 r-method-item-wrapper">
                                            <div
                                                class="r-panel-title-sub r-method-item-title hover-modal-optional relative">
                                                <span>Referral Code</span>
                                                <span v-if="referralCodeCheckFlag" class="pl-2 primary--text">(5% BONUS)
                                                </span>
                                                <span class="ml-1">🔥</span>
                                                <v-icon class="pointer ml-1" color="primary" size="12">{{
                                                    icons.mdiHelpCircle }}</v-icon>
                                                <div class="qr-code-dialog-wrapper boxCardBg elevation-1 w24">
                                                    <div>🔥</div>
                                                    <div>JJJJ</div>
                                                    <div>9292</div>
                                                    <div>Onlibox</div>
                                                </div>

                                            </div>
                                            <div class="r-method-item-child-wrapper mt-2">

                                                <v-text-field v-model="referralCode" @input="referralCodeCheck" @blur="referralCodeCheck"
                                                    class="font-weight-bold" solo flat :hide-details="true">
                                                    <template #append>
                                                        <v-icon color="green"
                                                            :class="[referralCodeCheckFlag ? '' : 'grayscale']">{{
                                                                icons.mdiCheckCircle }}</v-icon>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                        </div>

                                        <div class="pt-4 r-method-item-wrapper">
                                            <div class="r-panel-title-sub r-method-item-title">Deposit Amount</div>
                                            <div class="r-method-item-child-wrapper mt-2">

                                                <v-text-field v-model="depositNumInput" class="font-weight-bold" solo flat
                                                    :maxlength="2" :hide-details="true" type="number">
                                                    <template #prepend-inner>
                                                        <span class="black--text font-weight-bold" label>$</span>
                                                    </template>
                                                </v-text-field>

                                                <div class="mt-4 deposit-num-grid">
                                                    <div class="deposit-num pointer" v-ripple="{class:'rightBg--text'}" @click="depositNumInput = 5">
                                                        <div class="deposit-num-item">
                                                            <span opacity-60 mr-1>MIN</span>
                                                            <span>$5</span>
                                                        </div>
                                                    </div>
                                                    <div class="deposit-num pointer" v-ripple="{class:'rightBg--text'}"  @click="depositNumInput = 10">
                                                        <div class="deposit-num-item">$10</div>
                                                    </div>
                                                    <div class="deposit-num pointer" v-ripple="{class:'rightBg--text'}"  @click="depositNumInput = 25">
                                                        <div class="deposit-num-item">$25</div>
                                                    </div>
                                                    <div class="deposit-num pointer" v-ripple="{class:'rightBg--text'}"  @click="depositNumInput = 50">
                                                        <div class="deposit-num-item">$50</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <v-btn @click="clickDeposit" block depressed color="black" class="mt-8"
                                                :loading="loading" :disabled="Number(depositNumInput)<5">
                                                deposit
                                                <span class="mx-1 white rounded px-1" v-if="depositNumInput">${{
                                                    depositNumInput }}</span>
                                                <span class="ml-2">get</span>
                                                <span class="mx-1 white rounded px-1" v-if="depositNumInput">
                                                    ${{ getDepositNumber }}</span>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-tab-item>
                            <v-tab-item value="tab-1">
                                <div class="">

                                    <div class="r-method-wrapper">
                                        <div class="d-flex justify-start align-center">
                                            <div class="r-panel-title pointer" @click="payHandlerSwiperTab = 'tab-0'">
                                                <v-icon v-ripple="false" class="white rounded" size="25">{{
                                                    icons.mdiChevronLeft
                                                }}</v-icon>
                                            </div>
                                            <div class="pl-6 pt-1">{{ Number(payResult?.amount / 100).toFixed(0) }} {{
                                                payResult?.remark?.token }}</div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="pay-attention">
                                                <p>
                                                    <span class="red--text">Attention!</span> This address only supports
                                                    USDT transfers via the BEP20
                                                    protocol. Do not send transfers from smart contracts, BSC coins or any
                                                    other crypto to it. Such deposits will not be credited. Send the exact
                                                    amount of {{ Number(payResult?.amount / 100).toFixed(0) }} {{
                                                        payResult?.remark?.token }} to the address shown on this page in one
                                                    transaction.
                                                </p>
                                                <p>Send exactly the amount shown in one transaction, otherwise your deposit
                                                    may fail. Payments in multiple transactions are not supported.

                                                    The funds will be credited as soon as we get 1 confirmations from the
                                                    BSC network.</p>

                                                <p>
                                                    <span class="red--text">Attention!</span> Please note that the address
                                                    the system gave you for this
                                                    payment is unique and can only be used once. Each payment needs to be
                                                    initiated anew.

                                                    Crypto deposits are monitored according to our AML program.
                                                </p>
                                            </div>
                                        </div>


                                        <div class="mt-6">
                                            <div>Wallet address</div>
                                        </div>

                                        <v-sheet height="100px"
                                            class="mt-4 d-flex align-center justify-space-between address-wrapper">
                                            <v-sheet class="pa-2 black">
                                                <qrcode-vue :size="80" :value="payResult?.remark?.address"></qrcode-vue>
                                            </v-sheet>
                                            <div class="d-flex justify-center flex-column px-4">
                                                <v-sheet class="font-weight-light">
                                                    <div style="word-break: break-all;">{{ payResult?.remark?.address }}
                                                    </div>
                                                </v-sheet>
                                                <v-btn color="black" x-small block class="mt-2"
                                                    @click="copyText(payResult?.remark?.address)">Copy Address</v-btn>
                                            </div>
                                        </v-sheet>



                                    </div>
                                </div>
                            </v-tab-item>

                        </v-tabs-items>




                    </div>
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { BUS_NAME, CACHE_KEY } from "@/utils/constants";
import { deposit } from "@/api/wealth.js";
import { referralCodeCheck } from "@/api/user";
// import { debounce, throttle } from "@/utils/functions";
import { debounce } from 'lodash-es'
import { mdiChevronLeft, mdiHelpCircle, mdiCheckCircle } from "@mdi/js"
import QrcodeVue from 'qrcode.vue'
import BigNumber from "bignumber.js"

export default {
    name: "DepositNext",
    components: {
        QrcodeVue,
    },

    computed: {
        getDepositNumber ({ depositNumInput, referralCodeCheckFlag }) {

            if (!referralCodeCheckFlag) {
                return depositNumInput
            }
            if (depositNumInput) {
                //depositNumInput*0.5
                return new BigNumber(depositNumInput).times(0.05).plus(depositNumInput).toNumber()
            }
        },
    },
    data () {
        return {
            //

            icons: {
                mdiChevronLeft, mdiHelpCircle, mdiCheckCircle
            },
            showDepositDialog: false,
            activePayType: 'paypal',
            payTypeMethodInfoEnum: {
                stripe: {
                    name: 'Stripe',
                    payType: 3,
                    list: [],
                },

                paypal: {
                    name: 'Paypal',
                    payType: 5,
                    list: [],

                },
                bsc: {
                    name: 'BNB Smart Chain',
                    payType: 8,
                    chainId: 56,
                    list: [
                        {
                            name: 'Tether USD',
                            icon: 'usdt.png',
                            desc: 'USDT',
                            code: 'USDT',
                            active: true,
                        },
                        {
                            name: 'USD Coin',
                            icon: 'usdc.png',
                            desc: 'USDC',
                            code: 'USDC',
                            active: false,
                        },
                    ]

                },
                eth: {
                    payType: 8,
                    name: 'Ethereum',
                    list: [],
                },
                'withdraw-bsc': {
                    name: 'BSC',
                    list: [],
                },

            },
            selectCryptoCode: 'USDT',
            referralCode: '',
            depositNumInput: 10,
            depositNumList: [5, 10, 25, 50],
            loading: false,
            payHandlerSwiperTab: 'tab-0',
            payResult: {
                address: '0x00000000000000000000000000000000000000',
                amount: 0,
                chain: 56,
                code: 'USDT',
                payType: 8,
            },
            disabledTypes: ['stripe', 'eth', 'withdraw-bsc'],
            referralCodeCheckFlag: false,
        }
    },

    created () {
        this.initDepositBus()

        let referralCode = localStorage.getItem(CACHE_KEY.REFERRAL_CODE)

        if (referralCode && referralCode != 'undefined' && referralCode != '') {
            this.referralCode = referralCode
        }
        this.referralCodeCheck()

    },
    methods: {
        referralCodeCheck: debounce(function (event) {
            if (this.referralCode == '') {
                this.referralCodeCheckFlag = false
            } else if (this.referralCode?.toUpperCase() == 'ONLIBOX') {
                this.referralCodeCheckFlag = true
            } else {
                referralCodeCheck(this.referralCode).then(res => {
                    // 返回了默认值
                    if (res.data == 'ONLIBOX') {
                        this.referralCodeCheckFlag = false
                    } else {
                        this.referralCodeCheckFlag = true
                    }

                })
            }
        }, 500),
        copyText (text) {
            this.$clipboard(text)
            this.$awn.success("Address Copied!")
        },
        clickCoin (item) {
            if (item.active) {
                this.selectCryptoCode = item.code
            }
        },
        clickDeposit: debounce(function (event) {

            this.loading = true;
            // localStorage.setItem("rechargePath", this.$route.fullPath);
            let upgradeId = localStorage.getItem("upgradeId")
            deposit({
                amount: this.depositNumInput * 100,
                payType: this.payTypeMethodInfoEnum[this.activePayType].payType,
                promoCode: this.referralCode,
                upgradeId,
            })
                .then((res) => {
                    if (res.data) {

                        this.payResult = res.data
                        if (this.payResult.payType == 8) {
                            this.payHandlerSwiperTab = 'tab-1'
                        } else {
                            window.location.href = this.payResult.payUrl;
                        }
                    }
                    if (upgradeId) localStorage.removeItem("upgradeId");
                })
                .catch((err) => {

                }).finally(() => {
                    this.loading = false;
                })
        }, 2000, { leading: true, trailing: false }),
        initDepositBus () {
            this.$bus.$on(BUS_NAME.SHOW_DEPOSIT, () => {

                this.showDepositDialog = true
            })
        },
        selectPayType (type) {
            if (this.disabledTypes.includes(type)) return

            if (window.innerWidth < 600) {
                let scrollContainer = document.getElementById("r-panel");
                scrollContainer.scrollLeft = 99999
            }

            this.activePayType = type
        },
        getPayTypeActiveClass (type) {
            let disabledClass = ''

            if (this.disabledTypes.includes(type)) {
                disabledClass = ' r-method-item-method-disabled'
            }

            return type == this.activePayType ? 'r-method-item-method-active' : disabledClass;
        },
        closeSelf () {

            this.showDepositDialog = false;
        },
        //
        clickUnderstand () {

        }
    }
}
</script>
<style lang="scss" scoped>
    .v-tabs-items {
        background: transparent!important;   
    }

    .r-panel-dialog {
        
    }
    .r-panel-card {
        
        height: 36rem;
        
        .r-panel {
            height: 100%;
            
        }
        .r-panel-title {
            font-weight: 700;
            color: #FFF;
            .r-panel-title-sub {
                font-size: 1rem;
                font-weight: 600;

            }
        }
        .r-method-wrapper {
            padding: 0 8px;

        }
        .r-method-item-wrapper {
          .r-method-item-title {
            padding-left: 8px;
          }  
          .r-method-item-method-disabled {
            //opacity: .5;
          }
          .r-method-item-method-active {
            background: var(--v-black-base)!important;
            color: var(--v-white-base)!important;
          }
          .r-method-item-method {
            padding: 12px;
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;
            background: var(--v-dialog-base);
            //background: red;
            border-radius: 10px;

            transition: .2s cubic-bezier(.25, .8, .5, 1);
            cursor: pointer;

            .r-method-item-method-title-disabled {
                color: var(--v-primary-base);
                font-weight: 500;
            }

            .r-method-item-method-title-wrapper {
                .r-method-item-method-title {
                    padding-left: 10px;
                }

                .r-method-item-method-title-icon {
                    width: 30px;
                    height: 30px;
                }            
            }

          }
        }
        .r-panel-left {
            width: 35%;
            border-radius: 12px;
            background-color: #000000;
            height: 100%;
            .r-panel-left-header {
                width: 100%;
            }
            
        }
        .r-panel-right {
            width: 65%;
        }
        .r-method-item-child-wrapper-grid {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-gap: 1rem;
            grid-auto-flow: dense;
        }
        .r-method-item-child-wrapper {
            
            .r-method-item-child-item-active {
                border-color:#FFF!important;
            }
            .r-method-item-child-item {
                display: flex;
                justify-content: center;
                align-center: center;
                flex-direction: column;
                padding: 12px;
                border-radius: 10px;
                background: var(--v-cff_c51-base);
                border: 1px solid transparent;

                min-width: 120px;
                min-height: 120px;

                
                box-sizing: border-box;
                position: relative;

                .r-method-item-child-item-icon {
                    width: 60px;
                    height: 60px;
                    margin: auto;
                }
                .r-method-item-child-item-title {
                    width: 100%;
                    text-align: center;
                    font-size: 0.725rem;
                    
                }
                .coin-disabled {
                    opacity: 0.5;
                    color: #999;
                    font-weight: 400;
                    font-size: 0.5rem;
                    position: absolute;
                    bottom: 2px;
                    right: 0;
                    left: 0;
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .deposit-num-grid {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-gap: .5rem;
            grid-auto-flow: dense;
        }
        .deposit-num {
            text-align: center;
            font-size: 0.735rem;
            background: var(--v-cff_c51-base);
            //color: var(--v-primary-base);
            opacity: .8;
            border-radius: 6px;
            height: 30px;
            //width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            
        }
        .pay-attention {
            font-size: 0.735rem;
            font-weight: 500;
        }
    }
    .hover-modal-optional:hover .qr-code-dialog-wrapper{

        visibility: visible;
        opacity: 1;
        transition: all 0.5s;
    }
    .qr-code-dialog-wrapper {
    
        padding: 12px;
        position: absolute;

        left: 50%;
        top: 0;
        right: 50%;
        z-index: 999;
        transform: translateX(-50%);
        background: #fff;
        box-shadow: 0 6px 16px rgba(0,0,0,.12);
        border-radius: 12px;
    
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
    }

    @media (max-width: 600px) {
        /* 滚动条美化 */
        .scrollBar::-webkit-scrollbar {
            width: 2rem !important;
            height: .6rem !important;
        }

        .r-panel {
            //
            overflow-x: scroll;
            .r-panel-left {
                width: 100%;
                min-width: 12rem;
            }
            .r-panel-right {
                width: 100%;
                min-width: 20rem;
            }
            
            .r-method-item-child-wrapper {
            
                .r-method-item-child-item {
                    
                    min-width: 80px;
                    min-height: 80px;
    
                    .r-method-item-child-item-icon {
                        width: 30px;
                        height: 30px;
                        margin: auto;
                    }
                    .r-method-item-child-item-title {
                        
                        font-size: 0.625rem;
                        
                    }
                }
            }
            .pay-attention {
                font-size: 0.625rem;
                p {
                    margin-bottom: 8px;
                }
            }
            .address-wrapper {
                font-size: 0.75rem;
            }
        }
    }
</style>