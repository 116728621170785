<template>
  <div class="index_battlesList" id="index_clash_area">
    <div class="index_area_title_row pt-4 d-flex justify-space-between align-center">
      <div :cols="device === 'PC' ? 2 : 6" class="text-left px-0">
        <div class="index_area_title_row_left">
          FEATURED CLASHES
        </div>
      </div>
      <v-btn depressed small :loading="loading" @click="getList" :height="device === 'PC' ? '2rem' : ''" class="pa-2"
        color="black" :disabled="skeleton">
        SHUFFLE
      </v-btn>
    </div>
    <div class="goods-card-row-wrapper ">
      <swiper @touchMove="touchMove" class="goods-card-wrapper pt-4 scrollBarHidden" ref="clash-swiper"
        :options="swiperOptions">
        <swiper-slide v-for="(item, i) in list" :key="i" class="goods-card-slide">
          <ClashCardNext :skeleton="skeleton" @share="$emit('share',{clashId:item.id})" :info="item" class="goods-card-item shadow"></ClashCardNext>

        </swiper-slide>
      </swiper>

    </div>
    <div class="loading-block-btn-wrapper">
      <v-btn depressed block color="success" class="shadow" @click="toPage"
        v-ripple="{ class: `success--text darken-1` }">view all</v-btn>
    </div>


  </div>
</template>

<script>
import { battlePage } from "@/api/battles";

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css' // 注意这里的引入
import { debounce } from "lodash-es";
import ClashCardNext from "@/components/ClashCardNext.vue";
import dayjs from "dayjs";

let _this = null

export default {
  name: "IndexClashArea",
  components: {
    Swiper,
    SwiperSlide,
    ClashCardNext,
  },
  props: ['device'],
  computed: {
    swiper () {
      let swiper = this.$refs['clash-swiper']
      if (swiper) {
        return swiper.$swiper
      }
    },
  },
  data () {
    return {
      loading: true,
      skeleton: true,
      pageNo: 1,
      list: [{},{},{},{},{},],

      icons: {
      },
      queryPage: {
        startTime: dayjs().subtract(24, 'hour'),
        endTime: dayjs(),
        reqCount: 0,
      },
      swiperOptions: {
        allowSlidePrev: false,
        slidesPerColumnFill: 'row',
        slidesPerView: 2,
        slidesPerColumn: window.innerWidth > 768 ? 1 : 2,
        slidesPerGroup: 2,
        spaceBetween: 16,
        touchMoveStopPropagation : false,
        breakpoints: {
          1024: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 16
          },
          768: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 8
          },
        },
        on: {
          slideNextTransitionEnd: function(swiper){
            console.log("slideNextTransitionEnd")
            // _this.pageNo = 1
            _this.getListDebounce(false)
          },
        },
      },
    }
  },
  created () {
    _this = this
    //初始化数据
    this.getList();
  },
  methods: {
    touchMove(e,e1,e2) {
      if(e) {
        e.stopPropagation()
      }
    },
    getListDebounce: debounce(function (nextSlide) {
      this.getList(nextSlide)
    }, 1000, {
      leading: true,
      trailing: false
    }),
    getList (nextSlide=true) {
      this.loading = true
      let pageSize = window.innerWidth > 768 ? 10 : 8
      
      battlePage({
        pageNo: this.pageNo,
        pageSize,
        battleStatusArr: 2,
        sortEnum: 'TEAM_PRICE_HIGH',
        // 最近一小时
        startTime: this.queryPage.startTime.format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.queryPage.endTime.format('YYYY-MM-DD HH:mm:ss'),
      }).then(res => {
        if(this.skeleton) {
          this.list = []
        }
        // 如果没有数据，重新请求,最多请求3次
        if(res?.data?.list.length === 0) {
          setTimeout(() => {
            this.queryPage.reqCount++
            this.queryPage.startTime = this.queryPage.startTime.subtract(7, 'days')
            console.log("this.queryPage.startTime",this.queryPage.startTime.format('YYYY-MM-DD HH:mm:ss'))
            this.getList()
          }, 1000)
          return
        }
        this.list.push(...res?.data?.list || [])
        if (this.swiper) {
          this.swiper.update()
        }
        if (this.pageNo > 1 && nextSlide) {
          this.$nextTick(() => {
            if (this.swiper) {
              this.swiper.slideNext()
            }
          })
        }
        this.pageNo++
        if(this.skeleton) {
          this.skeleton = false
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })

    },
    toPage () {
      this.$router.push({
        path: "/clash"
      })
    }

  }
}
</script>

<style scoped lang="scss">
.index_battlesList {
  padding-left: 0!important;
  padding-right: 0!important;
  .index_area_title_row {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
}
$width: auto;
$aspectRatio: calc(750 / 1050);
.goods-card-row-wrapper {
  position: relative;
  min-height: calc( $width / $aspectRatio + 32px);
  margin: 0;

}
.goods-card-wrapper {
  position: relative;
  padding: 16px;

  .goods-card-item {
    width: $width;
    //height: calc( $width / $aspectRatio);
    transition: .3s cubic-bezier(.25, .8, .5, 1);
    @media (any-hover: hover) {
      &:hover {
        transform:  translateY(-6px);
      }
    }

  }
  .card_box {
    margin: 0 !important;
  }
}
.loading-block-btn-wrapper {
  padding: 16px;
}

</style>

