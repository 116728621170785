import request from '@/utils/request'
// 获得聊天记录
export function getHistoryList() {
  return request({
    url: '/app-api/chat/group-message/list',
    method: 'GET',
    params: {
      pageSize:50
    }
  })
}
export function getHistoryList0(data) {
  return request({
    url: '/app-api/chat/group-message/list0',
    method: 'post',
    data
  })
}

/**
 * 发送文本消息
 * @param data text
 * @returns {*}
 */
export function sendText(data) {
  return request({
    url: '/app-api/chat/group-message/text',
    method: 'post',
    params:data
  })
}

