import Vue from "vue"
import icon from '@/components/SvgIcon.vue'
// 到这里 svg 雪碧图已经生成
import 'virtual:svg-icons-register'

//图标自动导入
//利用webpack 的require.context自动导入
//返回的req是只去加载svg目录中的模块的函数
// const req = require.context('./svg',false,/\.svg$/)
// new URL
// req.keys().map(req);

//Icon组件全局注册
 Vue.component('icon',icon)
