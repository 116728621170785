import { CACHE_KEY } from "@/utils/constants";
import localforage from 'localforage'
import { saveAnonymity } from "@/api/cart";


export async function saveAnonymityHandler (that) {
    let anonymityCartList = await localforage.getItem(CACHE_KEY.ANONYMITY_CART) || []
    let user_id = await localforage.getItem(CACHE_KEY.ANONYMITY_CART_USER_ID) || []
    if (anonymityCartList.length > 0) {
        console.log('anonymityCartList', anonymityCartList)
        // 保存到购物车
        // goodsSkuIds
        let res = await saveAnonymity({
            userId: user_id,
            uuidList: anonymityCartList.map(l => l.uuid)
        })
        console.log('saveAnonymity', res)
        localforage.removeItem(CACHE_KEY.ANONYMITY_CART)
        localforage.removeItem(CACHE_KEY.ANONYMITY_CART_USER_ID)
        localforage.setItem(CACHE_KEY.ANONYMITY_CART_FLAG, 1)
        if(res.data > 0){
            that.$router.push(`${import.meta.env.VITE_APP_REGISTER_BOX_URL}?m=register`)
        }

    }
}
export async function loginSuccessHandler (that, res, type = 'login') {
    that.$store.dispatch("user/setAuthorization", res.data);

    that.$store.dispatch("user/getUserInfo");
    that.$store.dispatch("user/getAccountInfo");

    if (type == 'login') {
        let redirect = that.$route.query.redirect
        if ((redirect ?? '') !== '') {
            setTimeout(() => {
                if (redirect.startsWith('/')) {
                    window.location.href = that.$route.query.redirect
                } else {
                    that.$router.replace(that.$route.query.redirect)
                }
            }, 1000)
        }
    }
    saveAnonymityHandler(that)
}
