<template>
  <div class="flex w-full boxCardBg rounded-lg cursor-pointer" @click="clickOpenGoodsInfo()">
    <div class="w-[30%] " p-1>
      <div class="h-20 flex items-center justify-center rounded-lg " p-2
        :style="{ backgroundColor: bgcolor[dayStatus][shopInfo.level] }">
        <v-img aspect-1 h-14  :src="$S3Utils.resizeGoodsPicture(shopInfo.picture)" class="rounded-lg" contain
          :alt="shopInfo?.title ?? shopInfo.picture"></v-img>
      </div>
    </div>
    <div class="w-[70%] flex-1 flex justify-between flex-col" pb-1 p-2>
      <div class="text-2.5 line-height-4 md:text-3 two-line-text">
        {{ shopInfo.title }}
      </div>
      <div class=" flex justify-between items-end">
        <div class="">
          ${{ (shopInfo.price / 100) | matterPriceDecimal }}
        </div>
        <div class="">
          <v-icon class="w-4" color="primary">
            {{ icons.mdiInformationSlabCircle }}
          </v-icon>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import bgcolor from "@/utils/goodsLevelColor2.js";
import { mapState } from "vuex";
import { mdiInformationSlabCircle } from "@mdi/js";
export default {
  name: "bestCard",
  props: {
    shopInfo: {
      default: () => {
      },
    },
  },
  data () {
    return {
      icons: {
        mdiInformationSlabCircle,
      },
      bgcolor,
    };
  },
  computed: {
    ...mapState({ dayStatus: (state) => state.user.dark })
  },
  created () {
  },
  mounted () {
  },

  methods: {
    clickOpenGoodsInfo () {
      this.$emit("clickOpenGoodsInfo", this.shopInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
