export const FRIEND_STATUS_ENUM_FRIEND_MAP = {
    '0': {icon: "mdiCheck", description: "Confirmed",actionTitle: "Confirmed",code:"friend_status_confirmed"},
    '1': {icon: "mdiAccountClock",description: "Pending", actionTitle: "Pending",code:"friend_status_pending"},
    '10': {icon: "mdiPlus",description: "Not a friend yet", actionTitle: "Add Friend",code:"friend_status_invite"},
}
export const FRIEND_STATUS_ENUM_SESSION_MAP = {
    '0': {icon: "mdiCheck", description: "Confirmed",code:"friend_status_confirmed"},
    '1': {icon: "mdiAccountClock",description: "Pending",code:"friend_status_pending"},
    '10': {icon: "mdiAccountOff",description: "Not a friend yet",color:'red',code:"friend_status_not_a_friend"},
}
export const FRIEND_STATUS_ENUM = {
    NORMAL:0,
    TEMP_CHAT:1,
    NON_FRIEND:10,
}
