import request from '@/utils/request'

// 物品栏分页
export function getCartPage(params) {
  return request({
    url: '/app-api/product/cart',
    method: 'GET',
    params
  })
}

export function getCartPage0(params) {
  return request({
    url: '/app-api/product/cart/page',
    method: 'GET',
    params
  })
}
export function getCartAll(params) {
  return request({
    url: '/app-api/product/cart/all',
    method: 'GET',
    params
  })
}
//卖出物品
export function sellCarts(data) {
  return request({
    url: '/app-api/product/cart/sell',
    method: 'PUT',
    data
  })
}
// 保存匿名免费玩 [skuId]
export function saveAnonymity(data) {
  return request({
    url: '/app-api/product/cart/anonymity/save',
    method: 'POST',
    data
  })
}

//获取用户产品数量统计
export function getGoodsCountByUser(params) {
  return request({
    url: '/app-api/product/cart/statistics-count',
    method: 'GET',
    params
  })
}

//获取用户产品数量统计 {boxId}
export function getGoodsCountByUserBox(params) {
  return request({
    url: '/app-api/product/cart/statistics-count-box',
    method: 'GET',
    params
  })
}

/**
 * 获取用户产品spu
 * @param {*} params userId,type=1(collect),goodsFlag=1
 * @returns 
 */
export function getUserCartItemSpuExpand(params) {
  return request({
    url: '/app-api/product/cart/goods/list',
    method: 'GET',
    params
  })
}
/**
 * 操作用户产品spu
 * @param {*} params goodsId,type=1(collect), action=[collect,cancel]
 * @returns 
 */
export function updateUserCartItemSpuExpand(data) {
  console.log(data)
  return request({
    url: `/app-api/product/cart/goods/${data.type}/${data.action}`,
    method: 'POST',
    params: {
      goodsId: data.goodsId,
    }
  })
}