import request from '@/utils/request'
// 获取聊天列表服务接口
export function getChatHistoryList(data){
  return request({
    url: '/app-api/chat/msg/history',
    method: 'post',
    data
  })
}
// {text: ''}
export function sendMsgText(data){
  return request({
    url: '/app-api/chat/send/text',
    method: 'post',
    data
  })
}
// link title
export function sendMsgLink(data){
  return request({
    url: '/app-api/chat/send/link',
    method: 'post',
    data
  })
}
// title battleId title
export function sendMsgBattle(data){
  return request({
    url: '/app-api/chat/send/battle',
    method: 'post',
    data
  })
}
// {toUserId: '',formUserId: '',}
export function toChat(data){
  return request({
    url: '/app-api/chat/toChat',
    method: 'post',
    data
  })
}
// {nick:'',email:'',userId:''}
export function getSessionList(data){
  return request({
    url: '/app-api/chat/session/list',
    method: 'post',
    data
  })
}
