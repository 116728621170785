<template>

<div>
  <div class="menu_nav_placeholder"></div>
  <div id="header-row-wrapper" class="index_top d-flex justify-end align-center  xl:pl-[70px]" pl-4
  :class="{ index_top_model: device !== 'PC','index_top_notice': $store.state.config.headerNoticeConfig.show }" @mousewheel.stop="">
    <!-- 头部左侧 -->
    <div class="top_left d-flex align-center" :class="{
      top_left_model: device !== 'PC',
    }"> <!-- <v-img
        :contain="true
        :src=logoUrl
        style="width: 8.06rem; height: 1.105rem; flex: 0 0 auto"
        :class="{ img_img: device == 'PC' }"
        @click="reLoadIndex"
      ></v-img> -->
      <div style="width: 8rem; flex: 0 0 auto">
        <v-img v-ripple="{class:'rightBg--text'}" @click="reLoadIndex" :lazy-src="$S3Utils.resize(logoUrl,	265,62,'fill',{png: { quality: 1 }})"
               :src="$S3Utils.resize(logoUrl,1060,248,'fill',{png: { quality: 1 }})"
               contain alt="logo"
               height="2rem" class="hover-scale"
               id="header-logo">
        </v-img>
      </div>
      <div class="left-func flex" v-if="$breakpoint().ge('md')" id="header-menu">
        <!-- {{ $breakpoint().current }} -->
        <div v-for="(item, index) in navigator"
             :key="item.url"
             :id="item.name"
             :class="{
               'func-item': index !== navigator.length - 1,
               fitem: true,
               'header-menu-active': nowPage == index && nowPage !== '',
             }"
             @click="toPages(item.url, index)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 头部右侧 -->
    <!-- 1.未登录 -->
    <div class="top_right flex rcenter end" v-if="isLogin === false">

      <div id="headerAnonymityCartWrapper" style="display: none;">
        <div class="right_user flex rcenter between">
          <!-- PC端 -->
          <div class="user_money flex rcenter between" id="headerAnonymityCartHover">
            <div class="money_text text-truncate px-2" id="headerAnonymityCartBalance">$0</div>

            <v-sheet width="2rem" height="2rem" class="d-flex align-center justify-center">
              <icon className="header-car" name="header-car" style="width:1rem; height:1rem; color: #ffffff"></icon>
            </v-sheet>


          </div>
        </div>


      </div>

      <!-- pc端 -->
      <div class="right_login flex rcenter font-weight-bold" v-if="device == 'PC'">
        <div class="switch_box flex allcenter" v-if="showChangeTheme">
          <v-icon color="#FFF" size="1rem" dense @click="changeDark">
            {{getDarkThemeIcon}}
          </v-icon>

        </div>
        <div class="login_signUp hover-scale" @click="openRegister">REGISTER</div>
        <div class="login_box hover-scale" @click="openLogin">LOG IN</div>
      </div>
      <!-- 移动端 -->
      <div class="right_login flex rcenter" v-else>
        <div class="switch_box flex allcenter" style="padding: 11px; box-sizing: border-box; margin-right: 8px" @click="openLogin">
          <v-icon color="#FFF" >
            {{icons.mdiAccount}}
          </v-icon>
        </div>
        <div class="switch_box flex allcenter" style="padding: 11px; box-sizing: border-box">
          <v-icon color="#FFF" @click="showMenu=true" :ripple="false">{{icons.mdiDotsHorizontal}}</v-icon>
        </div>
      </div>
    </div>
    <!-- 2.已登录 -->
    <div class="top_right flex rcenter end" v-if="isLogin === true">
      <div class="right_user flex rcenter between">
        <!-- PC端 -->
        <div class="hover-scale user_account flex allcenter" v-if="device == 'PC'" v-ripple="{class:'rightBg--text'}" @click="
          $router.push({ path: '/userinfo?nowShow=account' })
        ">
          <div class="image_box">
<!--            <v-badge bordered bottom color="success" size="1rem" :content="userInfo.level || 0" offset-x=".6rem" offset-y=".6rem">-->
<!--              -->
<!--            </v-badge>-->
            <v-img class="image_box_avatar" :src="$S3Utils.resizeAvatar(userInfo.avatar)" alt="avatar"></v-img>
            <img class="image_box_level" :src="$S3Utils.getLevel(userInfo.level)" :alt="userInfo.level">

          </div>
          <div class="account_box rflex rcenter">
            <div class="account">ACCOUNT</div>
            <div class="persent">
              <div class="now_persent" :style="{
                width: (userInfo.exp / userInfo.levelExp) * 100 + '%',
              }"></div>
            </div>
          </div>
        </div>
        <div class="user_middle" v-if="device == 'PC'"></div>
        <div class="user_money flex rcenter between">
          <div class="money_text text-truncate">
            $ {{ balance }}
          </div>
          <div class="hover-scale moeny_button flex allcenter"  id="header-plus" @click="$bus.$emit('showDeposit')">
            <icon className="header-plus" name="header-plus" style="width: 50%; height: 50%"></icon>
          </div>
        </div>
      </div>
      <div class="right_func flex rcenter" v-if="device == 'PC'">
<!--        ["friend", "car", "more"]-->
        <div class="func_item flex allcenter" v-if="showChangeTheme">
          <v-icon id="header-dark" color="#FFF" size="18" @click="changeDark">
            {{getDarkThemeIcon}}
          </v-icon>
        </div>
        <div class="func_item flex allcenter" id="header-pchat" @click="modelFunc('showGroupChat')" >
          <v-badge   bordered
                     bottom
                     color="success"
                     dot
                     offset-x="-16"
                     offset-y="16"
                     v-if="groupUnreadCount>0"
          >
          </v-badge>
          <icon className="header-pchat" name="header-pchat" style="width: 50%; height: 50%"
                ></icon>
        </div>
        <div class="func_item flex allcenter" id="header-friend" @click="modelFunc('showFriendMessage')">

          <v-badge   bordered
                     bottom
                     color="success"
                     dot
                     offset-x="-16"
                     offset-y="16"
                     v-if="chatMsgUnreadDot"
          >
          </v-badge>
          <icon className="header-friend" name="header-friend" style="width: 50%; height: 50%"
                ></icon>
        </div>
        <div class="func_item flex allcenter" id="header-car" @click="modelFunc('showCar')">
          <icon className="header-car"  name="header-car" style="width: 50%; height: 50%; color: #ffffff"
                ></icon>
        </div>
        <div class="func_item flex allcenter"  id="header-more" v-show="true">


          <div class="text-center">
            <v-menu offset-y  :ripple="false"
                    transition="slide-x-transition"
                    bottom class="z-11"
                    right>
              <template v-slot:activator="{ on, attrs }">
                <div
                v-bind="attrs"
                v-on="on">
                  <v-icon dark>{{icons.mdiDotsHorizontal}}</v-icon>
                </div>
              </template>

              <div class="more-menu-title-box z-22">
                <div v-for="(item, index) in moreMenuList" :key="index" class="c66_ccc--text more-menu-title hover-scale-md" @click="clickMoreMenu(item)">
                  <icon v-if="item?.svg" :name="item.svg" :className="item.svg" class="mx-2 more-menu-title-icon"></icon>
                  <v-icon v-else color="c66_ccc" class="mx-2 more-menu-title-icon">{{icons[item.icon]}}</v-icon>
                  <div>{{ item.title }}</div>
                </div>
              </div>


            </v-menu>
          </div>

        </div>
      </div>
      <!-- 移动端功能 -->
      <div class="right_func flex rcenter" v-else>
        <div class="func_item flex allcenter" id="header-pchat" @click="modelFunc('showGroupChat')">
          <v-badge   bordered
                     bottom
                     color="success"
                     dot
                     offset-x="-16"
                     offset-y="16"
                     v-if="groupUnreadCount>0"
          >
          </v-badge>
          <icon className="header-pchat" name="header-pchat" style="width: 50%; height: 50%"
            ></icon>
        </div>
        <div class="func_item flex allcenter" @click="modelFunc('showCar')" id="header-car">
          <icon className="header-car" name="header-car" style="width: 50%; height: 50%; color: #ffffff"
            ></icon>
        </div>
        <div class="func_item flex allcenter" @click="showMenu=true">
          <icon id="header-more" className="header-more" name="header-more" style="width: 50%; height: 50%; margin-right: 0%; "
            ></icon>
        </div>
      </div>
    </div>

      <!-- 群聊聊天弹出框 -->
    <vue-lazy-component :timeout="1000">
      <GroupChatHover v-model="showGroupChat" ref="groupChatRef" :device="device"></GroupChatHover>
    </vue-lazy-component>


    <!-- 购物车弹出框 -->
    <!-- <car-box v-if="showCar" @close="closePop('showCar')" @upgrade="openUpgrade" :device="device"></car-box> -->
    <vue-lazy-component :timeout="1000">
      <CartHoverBox v-model="showCar" :device="device"></CartHoverBox>
    </vue-lazy-component>

    <vue-lazy-component :timeout="1000">
      <!-- 信息弹出框 -->
      <FriendChannelListHoverBox v-model="showFriendMessage"
                                 :showMessage="showFriendMessage"
        :device="device"></FriendChannelListHoverBox>
    </vue-lazy-component>


    <!-- 更多弹出框 -->
<!--    <more-box v-if="showMore" @close="closePop('showMore')" :device="device" style="position: fixed">-->
<!--    </more-box>-->

    <!-- 移动端菜单 -->
    <model-menu v-model="showMenu" v-if="device!='PC'" @modelMenuClick="modelMenuClick"
        :isLogin="isLogin"
      :device="device" @changeDark="changeDark"></model-menu>

      <support-box v-if="showHelp" @closeFun="showHelp = false"></support-box>
      <message-box
          v-if="showMessage"
          @close="showMessage = false"
      ></message-box>


    <!-- 充值框 -->
<!--    <recharge-box v-if="showRecharge" @closePop="closePop('showRecharge')" :device="device"></recharge-box>-->
    <!-- 升级框 -->
<!--    <upgrade v-if="showUpgrade" @closePop="closePop('showUpgrade')" :device="device" :initList="upgradeList"-->
<!--      style="position: fixed"></upgrade>-->
    <!-- <div class="mask" v-show="showMenu" @mousewheel.prevent=""></div> -->
  </div>
</div>
</template>

<script>

import CartHoverBox from "@/components/CartHoverBox.vue";
import GroupChatHover from "@/components/groupChat/GroupChatHover.vue";
import carBox from "@/components/header/car/carBox.vue";
import FriendChannelListHoverBox from "@/components/chat/FriendChannelListHoverBox.vue";
// import moreBox from "@/components/header/more/moreBox.vue";
// import loginBox from "@/views/sign/login.vue";
// import registBox from "@/views/sign/register.vue";
import modelMenu from "@/components/header/model/modelMenu.vue";
// import setPassword from "@/views/sign/setPassword.vue";
import supportBox from "@/components/header/more/helpBox.vue";
import messageBox from "@/components/header/more/emailBox.vue";
import { getConfig } from "@/api/user";
import { mapState } from "vuex";
import { priceFormat } from "@/utils/functions"
import {ICON_NAME} from "@/utils/constants";
import { mdiAccount,mdiDotsHorizontal,mdiEmail,mdiHeadphonesSettings,mdiTruckCheckOutline,mdiCart,mdiLogoutVariant } from "@mdi/js"
import {BUS_NAME} from "@/utils/constants";
import { logout } from '@/api/user'

export default {
  name: "headerCard",
  components: {
    // supportBox,
    messageBox,
    GroupChatHover,
    CartHoverBox,
    carBox,
    FriendChannelListHoverBox,
    // moreBox,
    // loginBox,
    // registBox,
    modelMenu,
    supportBox,
    // rechargeBox,
    // setPassword,
  },
  data() {
    return {
      icons:{mdiAccount,mdiDotsHorizontal,mdiEmail,mdiHeadphonesSettings,mdiTruckCheckOutline,mdiCart,mdiLogoutVariant},
      showHelp: false,
      showMessage: false,
      //群聊聊天框显示
      showGroupChat: false,
      //用户是否登录
      // isLogin: this.$store.state.user.userId ? true : false,
      // isLogin: "",
      //购物车显示
      showCar: false,
      //用户好友信息列表框显示
      showFriendMessage: false,
      //更多内容显示
      showMore: false,
      moreMenuList: [
        // { title: 'Messages',code:'messages',icon: mdiEmail },
        // { title: 'Deliveries',code:'deliveries',icon: mdiTruckCheckOutline },
        // { title: 'Support',code:'support',icon: mdiHeadphonesSettings },

        { svg: 'model-peo', icon: "", title: "ACCOUNT", auth: false, code: 'friends', url: '/userinfo?nowShow=account' },
        { svg: 'userInfo-07', icon: "", title: "STATS", auth: false, code: 'friends', url: '/userinfo?nowShow=stats' },
          { svg: 'userInfo-05', icon: "", title: "FAIRNESS", auth: false, code: 'friends', url: '/userinfo?nowShow=fairness' },
          { svg: 'userInfo-08', icon: "", title: "GAME HISTORY", auth: false, code: 'friends', url: '/userinfo?nowShow=history' },
          { svg: '', icon: 'mdiTruckCheckOutline', title: "DELIVERY", auth: false, code: 'friends', url: '/userinfo?nowShow=supportTickets' },
          { svg: 'userInfo-09', icon: "", title: "TRANSACTIONS", auth: false, code: 'friends', url: '/userinfo?nowShow=transitions' },
          { icon: "mdiLogoutVariant", title: "LOGOUT", auth: true, code: 'logout' }
      ],
      //显示菜单
      showMenu: false,
      //充值显示框
      //私聊聊天框显示
      showPrivateChat: false,
      // 升级框显示
      showUpgrade: false,
      // 升级框传递参数
      upgradeList: [],
      functionList: [],
      funcTab: ["switch", "pchat", "friend", "car", "more"],
      nowPage: "",
      balance: 0,
      showChangeTheme: import.meta.env.VITE_APP_DARK_ENABLE==='true' || false,
    };
  },

  computed: {
    ...mapState({
      singleUnreadCountList: (state) => state.chat.singleUnreadCountList,
      groupUnreadCount: (state) => state.chat.groupUnreadCount,
      logoUrl: (state) => state.config.configList.logoUrl,
      userInfo: (state) => state.user.userInfo,
      device: (state) => state.user.device,
      navigator: (state) => state.appData.navigator||[],
      accountInfo: (state) => state.user.accountInfo,
      isLogin: (state) => state.user.isLogin || false,
      dark: (state) => state.user.dark || 'day',
    }),
    chatMsgUnreadDot:({singleUnreadCountList})=>{
      return singleUnreadCountList.length>0
    },
    getDarkThemeIcon:({dark})=>ICON_NAME.darkThemeIcon[dark]
  },
  watch: {
    accountInfo: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.balance) {
          if (newVal.balance > 100000000) {
            if (newVal.balance > 100000000) {
              this.balance = (newVal.balance / 100000000).toFixed(2) + "M";
              return;
            }
          }
          this.balance = priceFormat(newVal.balance / 100);
        } else {
          this.balance = 0;
          return;
        }
      },
    },
    // dark: {
    //   immediate: true,
    //   handler(newVal) {
    //     if (newVal === 'night') {
    //       this.funcTab.splice(0, 1, "switch-day");
    //     } else {
    //       this.funcTab.splice(0, 1, "switch");
    //     }
    //   },
    // },
  },
  created() {
    // const dark = localStorage.getItem("dark");
    // this.dark = dark ? JSON.parse(dark) : false;
    // this.$store.dispatch("user/setDark", this.dark);
    //配置导航状态
    this.getNavStatus();
    //获取用户信息
    this.updateUserInfo();
    //绑定全局函数
    //1.用户数据更新
    this.$bus.$on("updataUserinfo", () => {
      this.updateUserInfo();
    });
    // 2.用户退出登录
    this.$bus.$on("outPut", () => {
      this.outPut();
    });
  },
  methods: {
    modelMenuClick(name){
      this[name] = true
    },
    getNavStatus() {
      this.$store.dispatch("appData/getNavigator",this.device)
    },
    openLogin(){
      this.$bus.$emit('showLogin0',{type:'login',open:true})
    },
    openRegister(){
      this.$bus.$emit('showLogin0',{type:'register',open:true})
    },
    //点击左侧跳转功能区
    toPages(pathurl, index) {
      this.nowPage = index;
      if (pathurl == "/freedrop" && !this.isLogin) {
        this.openLogin();
        return;
      }
      if(pathurl.startsWith("http")){
        window.open(pathurl,'_blank')
      } else {
        this.$router.push(pathurl);
      }
    },
    changeDark() {
      if (this.showChangeTheme) {
        let dark = this.dark === 'day' ? 'night' : 'day';
        this.$store.dispatch("user/setDark", dark);
        this.$vuetify.theme.dark = dark === 'night';
      }
    },
    clickMoreMenu(item){
      let {code} = item
      if(code==='logout') {
        this.outPut()
      }
      else if(code==='support') {
        this.$bus.$emit(BUS_NAME.SHOW_HELP_BOX, true)
      }
      if (item?.url) {
        this.$router.push(item?.url);
        return
      }
    },
    //移动端右侧功能区
    modelFunc(name) {
      this[name] = !this[name];
    },
    //获取用户信息
    getUserInfo() {
      this.$store.dispatch("user/getUserInfo");
    },
    //用户账户信息
    getAccountInfo() {
      this.$store.dispatch("user/getAccountInfo");
    },
    //用户信息更新
    async updateUserInfo() {
      this.getUserInfo()
      this.getAccountInfo()
    },
    //用户退出登录
    outPut() {
      logout()
      this.$store.dispatch("user/logout");
      this.$router.replace({ path: "/" });
    },
    //关闭聊天框
    closePop(type) {
      this[type] = false;
    },
    //打开升级框
    openUpgrade(list) {
      this.upgradeList = list;
      this.showCar = false;
      this.showUpgrade = true;
    },
    reLoadIndex() {
      this.nowPage = "";
      this.$router.push("/");
    },
    // getDrak() {
    //   return localStorage.getItem("dark");
    // },
  },
};
</script>

<style lang="scss" scoped>
//.chat-enter-active,
//.chat-leave-active {
//  transition: all 0.3s ease;
//}
//
//.chat-enter {
//  transform: translateX(100%);
//}
//
//.chat-enter-to {
//  transform: translateX(0);
//}
//
//.chat-leave-to {
//  transform: translateX(100%);
//}
.menuable__content__active {
  z-index: 22!important;
}
.more-menu-title-box {
  padding: 1rem;
  background-color: var(--v-white_night-base);
  .more-menu-title {

    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    height: 50px;

    display: flex;
    justify-items: space-between;
    align-items: center;
    .more-menu-title-icon {
      width: 25px;
      height: 25px;
    }

  }
}
.index_top_notice {
  top: $header-notice-height!important;
}
.index_top {
  width: 100%;
  position: fixed;
  top: 0;
  height: $header-menu-height;
  //padding-left: 70px;
  box-sizing: border-box;
  background-color: black;
  z-index: 16;
  font-weight: 500;

  .top_left {
    cursor: pointer;
    flex-grow: 1;

    .left-func {
      // 12.5rem =  logo-8rem + pr-4.5rem
      width: calc(100% - 8rem);
      flex-wrap: wrap;
      padding: 0 4%;
      font-size: 14px;
      color: #fff;
      justify-content: flex-start;

      .fitem {
        font-family: Poppins;
        font-weight: 700;
      }
      .header-menu-active {
        color: var(--v-success-base);
      }
      .func-item {
        margin-right: 4%;
      }

      .fitem:hover {
        color: var(--v-success-base);
      }
    }

    .img_img {
      width: 83px !important;
      height: 11px !important;
    }
  }

  .top_left_model {
    width: 50%;
  }

  .top_right {
    font-size: 0.6rem;
    height: 2rem;
    cursor: pointer;
    @media (min-width: 100px) and (max-width: 600px) {
      width: 50%;
    }
    .right_user {
      color: #fff;
      margin-right: 8px;

      .user_account,
      .user_money {
        border-radius: 4px;
        height: 2rem;
        //height: 36px;
        //padding: .25rem;
        background-color: rgba(255, 255, 255, 0.2);
      }

      .user_account {
        padding: 2px 10px;

        .image_box {

          margin-right: 4px;

          border-radius: 50%;
          position: relative;
          min-width: 2rem;
          min-height: 1.5rem;

          .image_box_avatar {

            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            top: -0.5rem;
            overflow: hidden;
            position: absolute;
          }
          .image_box_level {
            position: absolute;
            right: -3PX;
            bottom: -5PX;
            padding: 1px;
            background: var(--v-success-base);
            border-radius: 100%;
            width: 1rem;
            height: 1rem;
            font-size: 0.625rem;
            padding: 1px;
            border: 1px solid #FFF;
            color: #FFF;
          }

          ::v-deep .v-badge__badge {
            width: 15px;
            height: 15px;
            line-height: 15px;
            min-width: 10px;
            padding: 0;
          }

          ::v-deep .v-badge__badge::after {
            content: "";
            border-color: #fff;
            border-width: 1px;
          }


        }

        .account_box {
          font-size: 9px;
          color: #fff;

          .persent {
            width: 100%;
            height: 4.68px;
            background-color: #fff;
            position: relative;
            overflow: hidden;

            .now_persent {
              height: 100%;
              max-width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: var(--v-success-base);
            }
          }
        }
      }

      .user_money {
        box-sizing: border-box;
        font-size: 11px;

        color: #fff;

        .money_text {
          padding: 4px;
        }

        .moeny_button {
          height: 1.5REM;
          width: 1.5REM;
          margin-right: 0.18REM;

          background-color: var(--v-success-base);
        }
      }

      .user_middle {
        width: 1px;
        height: 12.48px;
        margin: 0 10.92px;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    .right_func {
      .func_item {

        width: 2rem;
        height: 2rem;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.2);
        margin-right: 8px;
        &:hover {
          background-color: #E6C5FC;
        }
      }
    }

    .right_login {
      .switch_box {
        width: 2rem;
        height: 2rem;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.2);
        margin-right: 8px;
      }

      .login_signUp {
        width: 100px;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        //font-size: 10.92px;
        padding: 8px 0;
        box-sizing: border-box;
        background-color: var(--v-success-base);
        border-radius: 4px;
        color: whitesmoke;
        margin-right: 8px;
        text-align: center;
      }

      .login_box {
        width: 100px;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        //font-size: 10.92px;
        padding: 8px 0;
        box-sizing: border-box;
        color: whitesmoke;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        margin-right: 7.8px;
        //border: 0.78px solid #fff;

        text-align: center;
      }
    }
  }

  .chat_box {
    position: fixed;
    width: 287.04px;
    height: 403.26px;
    top: 0;
  }

  .chat_modleBox {
    width: 100%;
    height: 100%;
  }

}

.index_top_model {
  height: calc($header-menu-height + env(safe-area-inset-top, 0))!important;
  padding: env(safe-area-inset-top) 0 0 1rem;
}
</style>
