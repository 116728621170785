<template>
    <svg :style="{height:height,width: width}" :class="svgClass" aria-hidden="true" v-on="$listeners">
        <use :xlink:href="symbolId"  />
    </svg>
</template>

<script>


export default {
    name: 'SvgIcon',
    props: {
        prefix: {
            type: String,
            default: 'icon',
        },
        name: {
            type: String,
            required: true,
        },
        width: {
            type: String,
        },
        height: {
            type: String,
        },

        className: {
        type: String,
        default: "",
        },
    },
    computed: {
        symbolId ({prefix,name}) {
            return `#${prefix}-${name}`;
        },
        svgClass({className}) {
      if (className) {
        return "svg-icon " + className;
      } else {
        return "svg-icon";
      }
    },
    },
}
</script>
