import request from '@/utils/request'

// 创建对战
export function battleCreate(data) {
  return request({
    url: '/app-api/game/battle/create',
    method: 'POST',
    data
  })
}
export function battleCreatePrice(data) {
  return request({
    url: '/app-api/game/battle/create/price',
    method: 'POST',
    data
  })
}

// 获得对战列表
export function battleList(query) {
  return request({
    url: '/app-api/game/battle/list',
    method: 'GET',
    params: query
  })
}

// 取消对战
export function battleCancel(params) {
  return request({
    url: '/app-api/game/battle/cancel',
    method: 'delete',
	params
  })
}

// 获得对战 Deprecated
export function battleGet(query) {
  return request({
    url: '/app-api/game/battle/get',
    method: 'GET',
    params: query
  })
}

// 加入对战
export function battleJoin(data) {
  return request({
    url: '/app-api/game/battle/join',
    method: 'POST',
    data
  })
}

//获取对战分页
export function battlePage(query) {
  return request({
    url: '/app-api/game/battle/page',
    method: 'GET',
    params: query
  })
}

//获得对战分页-历史
export function battleHistory(query) {
  return request({
    url: '/app-api/game/battle/history',
    method: 'GET',
    params: query
  })
}

//机器人加入对战
export function battleJoinBot(data) {
  return request({
    url: '/app-api/game/battle/join/bot',
    method: 'POST',
    data
  })
}

//对战游戏统计
export function battleStatics(params) {
  return request({
    url: '/app-api/game/battle/statics',
    method: 'GET',
    params
  })
}


// 获得对战
export function getBattleRoundList(id) {
  return request({
    url: `/app-api/game/battle/${id}/round`,
    method: 'GET',
  })
}
// 获得对战
export function getBattleRoundLiteList(id) {
  return request({
    url: `/app-api/game/battle/${id}/round/lite`,
    method: 'GET',
  })
}
export function getBattleTeamMap(id) {
  return request({
    url: `/app-api/game/battle/${id}/team`,
    method: 'GET',
  })
}
export function getBattleBoxList(id) {
  return request({
    url: `/app-api/game/battle/${id}/box`,
    method: 'GET',
  })
}
export function getBattleBaseInfo(id) {
  return request({
    url: `/app-api/game/battle/${id}/info`,
    method: 'GET',
  })
}
