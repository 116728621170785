<template>
  <div class="helpBox cflex" :class="{ helpBox_model: device !== 'PC' }">
    <div class="box_title">
      <div class="title_text flex rcenter between">
        <div class="line"></div>
        <span>Support</span>
        <div class="line flex allcenter" @click="$emit('closeFun')">
          <div></div>
        </div>
      </div>
      <div style="width: 100%; box-sizing: border-box; padding: 0 25px">
        <div class="title_search flex rcenter">
          <v-icon size="22">{{icons.mdiMagnify}}</v-icon>
<!--          <el-img-->
<!--            src="/assets/img/header/search.png"-->
<!--            fit="cover"-->
<!--            class="search_img"-->
<!--          ></el-img>-->
          <input
            type="text"
            placeholder="How we can help?"
            class="input"
            v-model.trim="searchKey"
            maxlength="20"
            @keydown.enter="serachInfo"
          />
        </div>
      </div>
    </div>
    <div class="box_content">
      <div class="content_item" v-show="searchInfo.length > 0"></div>
      <div v-show="searchInfo.length == 0" class="flex allcenter default">
        <span>Enter a word in the search bar above to find an article.</span>
      </div>
    </div>
    <div class="box_bottom flex between">
      <span></span>
      <div class="right_btn" @click="chat">Communicate online in real time</div>
    </div>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js"
const WEBSITE_ID = "653de1bd-eae0-4d68-a336-800f0f657a7e"

// // import { Crisp } from "crisp-sdk-web";
// Crisp.configure(WEBSITE_ID, { autoload: false });
// Crisp.configure(WEBSITE_ID, { locale: 'en' });
// // Crisp.chat.hide();
// Crisp.setHideOnAway(true);
const Crisp = window.$crisp;

export default {
  name: "helpBox",
  props: ["device"],
  data() {
    return {
      icons:{ mdiMagnify },
      searchKey: "",
      searchInfo: [],
    };
  },

  mounted() {},

  methods: {
    serachInfo() {

    },
    chat() {
      Crisp.chat.show();
      Crisp.chat.open();
      // setTimeout(()=>{
      //    Crisp.chat.hide(); // 必须show才能open
      // },500)
      // Crisp.setAvailabilityTooltip(false);
      // Crisp.setVacationMode(false);
      // Crisp.chat.onChatInitiated(() => {
      //   // Executed once the chat was initiated from the user
      //
      //    Crisp.chat.hide();
      // })
      Crisp.chat.onChatOpened(() => {
        // Executed once the chat was opened

        Crisp.chat.hide();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.helpBox {
  width: 361px;
  height: 570px;
  background-color: #fff;
  position: fixed;
  top: $header-menu-height;
  right: 24px;
  z-index: 1500;
  .box_title {
    width: 100%;
    padding: 0 0 15px;
    box-sizing: border-box;
    background-color: black;
    color: #fff;
    .title_text {
      width: 100%;
      padding: 9px 21px;
      .line {
        cursor: pointer;
        width: 15px;
        height: 15px;
        div {
          width: 15px;
          height: 2px;
          background-color: #fff;
        }
      }
    }
    .title_search {
      width: 100%;
      background-color: #fff;
      padding: 9px 11px;
      box-sizing: border-box;
      .search_img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .input {
        flex: 1;
        outline: none;
        color: #999;
        font-size: 15px;
      }
    }
  }
  .box_content {
    width: 100%;
    flex: 1;
    background-color: var(--v-white_night-base);
    .default {
      width: 100%;
      height: 100%;
      padding: 0 54px;
      font-size: 15px;
      color: #999999;
      text-align: center;
      box-sizing: border-box;
    }
  }
  .box_bottom {
    width: 100%;
    border-top: 1px solid #ececec;
    padding: 15px 21px;
    background-color: var(--v-white_grey02-base);
    span {
      font-size: 16px;
      line-height: 21px;
      height: 21px;
      align-self: flex-end;
      color: var(--v-black_grey-base);
    }
    .right_btn {
      cursor: pointer;
      padding: 9px 17px;
      background-color: var(--v-black-base);
      border-radius: 4px;
      color: var(--v-white-base);
      font-size: 15px;
      font-family: Poppins;
      font-weight: 700;
      line-height: 20px;
    }
  }
}
.helpBox_model {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .title_text {
    padding: 20px 31px !important;
  }
}
</style>
