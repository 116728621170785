<template>
  <div class="index_battlesList" id="index_boxes_area">
    <div class="index_area_title_row pt-4 d-flex justify-space-between align-center">
      <div :cols="device === 'PC' ? 2 : 6" class="text-left px-0">
        <h2 class="index_area_title_row_left text-5 md:text-6.8">
          {{ title || 'FEATURED BOXES' }}
        </h2>
      </div>
      <v-btn depressed small :loading="loading" @click="getList" :height="device === 'PC' ? '2rem' : ''" class="pa-2"
        color="black" :disabled="skeleton">
        SHUFFLE
      </v-btn>
    </div>
    <div class="goods-card-row-wrapper ">
      <swiper @touchMove="touchMove" class="goods-card-wrapper pt-4 scrollBarHidden" ref="box-swiper" :options="swiperOptions">
        <swiper-slide v-for="(item, i) in list" :key="i" class="goods-card-slide" >
          <box-card :skeleton="skeleton" :boxInfo="item" class="goods-card-item shadow" v-ripple="{class:'rightBg--text'}" :device="device"></box-card>
        </swiper-slide>
      </swiper>

    </div>
    <div class="loading-block-btn-wrapper">
      <v-btn depressed block color="success" class="shadow" @click="toPage"
        v-ripple="{ class: `success--text darken-1` }">view all</v-btn>
    </div>


  </div>
</template>

<script>
import GoodsCardNormal from "@/components/GoodsCardNormal.vue";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css' // 注意这里的引入

import boxCard from "@/components/boxCard.vue";
import { boxPage } from "@/api/product_box";

import { mdiRefresh } from "@mdi/js"
import { debounce } from "@/utils/functions";
let _this = null
// PRO盒子价格判断改成0-5，5-20，20-100
const PRICE_LEVEL_LIST = [

  // 0
  {
    lowestPrice: 0,
    maximumPrice: 500,
  },
  // 1
  {
    lowestPrice: 500,
    maximumPrice: 10000,
  },
  //2
  {
    lowestPrice: 10000,
    maximumPrice: 1000000,
  },
  // 3
  {
    lowestPrice: 1000000,
    maximumPrice: "",
  }]

export default {
  name: "IndexBoxesArea",
  components: {
    GoodsCardNormal,
    Swiper,
    SwiperSlide,
    boxCard,
  },
  props: ['device', 'title', 'priceLevel'],
  computed: {
    swiper () {
      let boxSwiper = this.$refs['box-swiper']
      if(boxSwiper) {
        return boxSwiper.$swiper
      }
    },
  },
  data () {
    return {
      loading: true,
      skeleton: true,
      pageNo: 1,
      list: [{},{},{},{},{},],

      icons: {
        mdiRefresh,
      },
      swiperOptions: {

        allowSlidePrev: true,
        slidesPerColumnFill: 'row',
        slidesPerView: 2,
        slidesPerColumn: window.innerWidth > 768 ? 1 : 2,
        slidesPerGroup: 2,
        spaceBetween: 16,

        breakpoints: {
          1024: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 16
          },
          768: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 8
          },
        },
        on: {
          slideNextTransitionEnd: function(swiper){
            console.log("slideNextTransitionEnd")
            _this.pageNo = 1
            debounce(_this.getList, 1000)
          },
        },
      },
    }
  },
  created () {
    _this = this
    //初始化数据
   this.getList();
  },
  methods: {
    touchMove(e,e1,e2) {
      if(e) {
        e.stopPropagation()
      }
    },
    getList () {
      this.loading = true
      let pageSize = window.innerWidth > 768 ? 10 : window.innerWidth > 425 ? 8 : 8
      boxPage({
        boxStatus: 1,
        //boxSign: 3,
        // 不回去用户的统计
        goodsCount: 0,
        pageNo: 1,
        from: this.pageNo == 1 ? 'HOME' : '',
        pageSize,
        sortEnum: this.pageNo > 1 ? 'SHUFFLE' : 'RECENTLY_DATE',
        lowestPrice: PRICE_LEVEL_LIST[this.priceLevel].lowestPrice,
        maximumPrice: PRICE_LEVEL_LIST[this.priceLevel].maximumPrice,
      }).then(res => {
        if(this.skeleton) {
          this.list = []
        }
        console.log({length:res?.data?.list?.length , pageSize})
        // 如果长度不够，进行填充
        if(res?.data?.list?.length < pageSize){
          res.data.list = res?.data?.list.concat(res?.data?.list.slice(0, pageSize - res?.data?.list?.length))
          console.log(res.data.list)
        }
        this.list.push(...res?.data?.list || [])
        if (this.swiper) {
          this.swiper.update()
        }
        if (this.pageNo > 1) {
          this.$nextTick(() => {
            if (this.swiper) {
              this.swiper.slideNext()
            }
          })
        }
        this.pageNo++
        if(this.skeleton) {
          this.skeleton = false
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })

    },
    toPage () {
      this.$router.push({
        name: "Unboxing",
        params: {
          priceLevel: this.priceLevel,
        }
      })
    }
    //获取对战数据

  }
}
</script>

<style scoped lang="scss">
.index_battlesList {
  padding-left: 0!important;
  padding-right: 0!important;
  .index_area_title_row {
    padding-left: 16PX!important;
    padding-right: 16PX!important;
  }
}
$width: auto;
$aspectRatio: calc(750 / 1050);
.goods-card-row-wrapper {
  position: relative;
  min-height: calc( $width / $aspectRatio + 32px);
  margin: 0;

}
.goods-card-wrapper {
  position: relative;
  padding: 16PX;

  .goods-card-item {
    width: $width;
    transition: .3s cubic-bezier(.25, .8, .5, 1);
  }
  .card_box {
    margin: 0 !important;
  }
}
.loading-block-btn-wrapper {
  padding: 16px;
}


</style>

