export const day = {
	"1": "#DCD3DC",
	"2": "#A9D0A9",
	"3": "#88A0BB",
	"4": "#F4CE7B",
	"5": "#FFC0CB",
	"6": "#A57D02"
};
export const night = {
	"1": "#727272",
	"2": "#2e4a26",
	"3": "#26374a",
	"4": "#4a2e26",
	"5": "#48264a",
	"6": "#a57d02"
};
export const OPEN_CARD_COLOR = {
	'day':[
		'',
        '#FFF5FF',
        '#CFFFCF',
        '#BADAFF',
        '#FFC13B',
        '#FFBFCA',
        '#FFC003',
	],
	'night':[
		'',
		'#8A8A8A',
		'#558A46',
		'#46668A',
		'#965D4D',
		'#86468A',
		'#FFC003',
	],
}
export default {
	day: day,
	night: night,
};
