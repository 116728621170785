/* eslint-disable no-console */

import {register} from 'register-service-worker'

if (navigator.serviceWorker) {

    register(`${import.meta.env.BASE_URL}service-worker.js`, {
        ready() {
            // console.log(
            //     '[sw:onlibox] App is being served from cache by a service worker.\n' +
            //     'For more details, visit https://goo.gl/AFskqB',
            // )
        },

        registered(registration) {
            console.log('[sw:onlibox] Service worker has been registered.')

            // Routinely check for app updates by testing for a new service worker.
            setInterval(() => {
                registration.update()
            }, 1000 * 60 * 60) // hourly checks
        },

        cached() {
            console.log('[sw:onlibox] Content has been cached for offline use.')
        },

        updatefound(registration) {
            console.log('[sw:onlibox] New content is downloading...')
            if (registration.installing) {
                // Wait until the new service worker is actually installed (ready to take over)
                registration.installing.addEventListener('statechange', () => {
                    if (registration.waiting) {
                        // if there's an existing controller (previous Service Worker), show the prompt
                        if (navigator.serviceWorker.controller) {
                            registration.waiting.postMessage('SKIP_WAITING')
                        } else {
                            // otherwise it's the first install, nothing to do
                            console.log('Service Worker initialized for the first time')
                        }
                    }
                })
            }
        },

        updated(registration) {
            console.log('[sw:onlibox] New content is available; please refresh.')

            const event = new CustomEvent('swupdatefound', {detail: registration})
            registration.waiting.postMessage('SKIP_WAITING')

            document.dispatchEvent(event)
        },

        offline() {
            console.log('[sw:onlibox] No internet connection found. App is running in offline mode.')
        },

        error(error) {
            console.error('[sw:onlibox] Error during service worker registration:', error)
        },
    })

    let refreshing

    navigator.serviceWorker.addEventListener('controllerchange', function () {
        if (refreshing) return

        window.location.reload()

        refreshing = true
    })

}
