<template>
  <div class="cart_page_item_info">
    <div class="cart_page_item_info_main flex">
      <div class="carImg flex allcenter"
      :style="{
        backgroundColor: bgcolor[dayStatus][shopInfo.goodsLevel],
      }">
        <v-img :lazy-src="$S3Utils.resizeGoodsPictureLazy(shopInfo.goodsPicture)"
               :src="$S3Utils.resizeGoodsPicture(shopInfo.goodsPicture,0.3)"
               height="76" :alt="shopInfo?.goodsTitle"
               contain class="car_img"></v-img>
      </div>
      <div class="cart_page_item_info_box">
        <div class="cart_page_item_info_title">{{ shopInfo.goodsTitle }}</div>
        <div class="cart_page_item_info_price">
          <span>${{ (shopInfo.skuPrice / 100) | matterPriceDecimal }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgcolor from "@/utils/goodsLevelColor2.js";
import {mapGetters, mapState} from "vuex";

export default {
  props: {
    shopInfo: {
      default: { des: "NOT AVALIABLIE ORDER", price: 0.05 },
    },
  },
  data() {
    return {
      bgcolor,
      // dayStatus: "",
      };
  },
  computed: {
    ...mapState({ dayStatus: (state) => state.user.dark })
  },
  created() {
    // const dark = localStorage.getItem("dark");
    // this.dayStatus = dark ? "day" : "night";
    // console.log("shopInfo", this.shopInfo);
  },
  mounted() { },

  methods: {},
};
</script>

<style lang="scss" scoped>

.cart_page_item_info {
  width: 100%;
  overflow: hidden;

  .cart_page_item_info_main {
    width: 100%;

    .carImg {
      width: 76PX;
      height: 76PX;
      position: relative;
      margin-right: 16px;
      border-radius: 10px;
    }

    .cart_page_item_info_box {
      flex: 1;
      font-weight: 700;
      margin-top: 6px;
      font-size: 12px;

      .cart_page_item_info_title {
        // line-height 18px => 36px
        height: 32PX /* 36/16 */;
        overflow: hidden;
      }
      .cart_page_item_info_price {
        margin-top: 6px;
        span {
          padding: 3px 8px;
          border-radius: 10px;
          background-color: var(--v-grey_grey02-base);
        }
      }
    }
  }
}
</style>
