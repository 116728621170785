const ONLIBOX_S3_START = "https://onliboxs3.s3"
const API_ENDPOINT = 'https://d122trkl556ygk.cloudfront.net'
// https://onliboxs3.s3.us-west-1.amazonaws.com/public/box/DUNK.png

const S3Utils = {
    resizeAvatar(url) {
        return this.resize(url, 90,90, "fill",{})
    },
    getLevel(level=0) {
        return `https://onliboxs3.s3-us-west-1.amazonaws.com/public/base_data/level/level-${level}.svg`
    },
    resizeBoxPictureLazy(url) {
        // edits: {quality: 50}
        return this.resize(url, 115,130, "fill",{})
    },
    // resizeGoodsPictureLazy(url) {
    //     // edits: {quality: 50}
    //     if((url??'')===''){
    //         return url
    //     }
    //     let param = {
    //         edits: {
    //             resize: {
    //                 ratio: 0.1,
    //                 "fit": "fill"
    //             },
    //         },
    //         outputFormat: 'webp',
    //     }
    //     return this.onliboxS3ConvertCloudfront(url, param)
    // },
    resizeGoodsPictureLazy(url) {
        return this.resizeGoodsPicture(url,0.3)
    },
    resizeGoodsPicture(url,ratio) {
        // edits: {quality: 50}
        if((url??'')===''){
            return url
        }
        let param = {
            edits: {
                resize: {
                    // ratio: 0.5,
                    "fit": "fill"
                },
            },
            outputFormat: 'webp',
        }
        if(ratio){
            param.edits.resize.ratio = ratio
        }
        return this.onliboxS3ConvertCloudfront(url, param)
    },
    resizeBoxPicture(url,deviceMobile=false) {
        let width = 575, height = 650
        if(deviceMobile){
            width = 230
            height = 260
        }
        return this.resize(url, width,height, "fill")
    },

    resizeHomeBanner(url) {

        if(url.lastIndexOf(".webp")!=-1){
            return url;
        }
        return this.resize(url,null,null,
            "fill",
            null,
            {outputFormat: 'webp'})
    },
    toWebp(url,ratio) {
        if((url??'')!=='' && url.lastIndexOf(".webp")!=-1){
            return url;
        }
        let param = {
            edits: {
                resize: {},
            },
            outputFormat: 'webp',
        }
        if(ratio){
            param.edits.resize.ratio = ratio
        }
        return this.resize(url,null,null,
            "fill",
            null,
            param)
    },
    /**
     *
     * @param url
     * @param width
     * @param height
     * @param resizeMode
     * @param editsParams
     * @param params = {
     *         requestType: RequestTypes;
     *         bucket: string;
     *         key: string;
     *         edits?: ImageEdits;
     *         originalImage: Buffer;
     *         headers?: Headers;
     *         contentType?: string;
     *         expires?: string;
     *         lastModified?: string;
     *         cacheControl?: string;
     *         outputFormat?: ImageFormatTypes;
     *         effort?: number;
     * }
     * @returns {string|*}
     */
    resize(url, width , height, resizeMode = "fill",editsParams,params) {
        if((url??'')===''){
            return url
        }
        let param = {
            edits: {
                resize: {
                    // disable,fill,cover,inside,outside
                    "fit": "fill"
                },
                ...editsParams
            },
            outputFormat: 'webp',
        }
        if(width){
            param.edits.resize.width = width
        }
        if(height){
            param.edits.resize.height = height
        }
        return this.onliboxS3ConvertCloudfront(url, param)
    },
    onliboxS3ConvertCloudfront(url, param) {
        if (url.startsWith(ONLIBOX_S3_START)) {
            let newUrl = url.slice(url.indexOf("//") + 2)

            let bucket = newUrl.slice(0, newUrl.indexOf("."))
            let key = newUrl.slice(newUrl.indexOf('/') + 1)
            let json = JSON.stringify({
                bucket, key, ...param
            })
            return `${API_ENDPOINT}/${btoa(json)}`
        }
        return url
    }
}
export default S3Utils;

