<template>
  <div class="cart_page">
    <div class="mask" @click.stop="close" @mousewheel.prevent="" @touchmove.prevent=""></div>
    <div
        class="cart_page_box"
        :class="{ cart_page_box_model: device !== 'PC' }"
        @click.stop=""
    >
      <div class="cart_page_box_main cflex" >
        <div
            class="box_top"
            style="width: 100%; padding: 0 16px; box-zising: border-box"
            @mousewheel.prevent="" @touchmove.prevent="" @click.stop=""
        >
          <div class="box_title flex rcenter between" @click="toCart" @mousewheel.prevent="" @touchmove.prevent="">
            <div class="title_left flex rcenter">
              <icon
                  name="header-car"
                  className="header-car"
                  class="title_img"
              ></icon>
              <div class="cleft_info cflex">
                <span class="tit">{{ queryPage.total }} items</span>
                <span style="color: var(--v-carFBg-base)">View Full Cart</span>
              </div>
            </div>
            <div class="title_right">
              <icon
                  class="right_img"
                  name="icon-right"
                  className="icon-right"
              ></icon>
            </div>
          </div>

          <div class="box_func flex" v-if="showSelectAll">
            <div class="func_all flex rcenter pointer" v-ripple="{ center: true }">
              <v-checkbox
                  v-model="selectAll"
                  class="pa-0 ma-0"
                  color="black"
                  :ripple="false"
                  hide-details
                  dense
              >
                <template v-slot:label>
                  <div class="select_all_text">SELECT ALL</div>
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>

        <div class="check_item_box">

          <v-virtual-scroll
              class="check_item_box_scroll scrollBar"
              :bench="10"
              :items="shopList"
              item-height="100"
              @scroll.stop="onscroll"
          >
            <template v-slot:default="{ item,index }">
              <v-row :class="{'check_item': true,check_item_PC: device == 'PC',}" align="center"
                     @click.stop="setSelectList(item,index)">
                <v-col :cols="1" class="pb-2">
                  <v-checkbox
                      :value="item.select"
                      readonly color="black"
                      class="pa-0 ma-0"
                      hide-details
                      dense
                      :ripple="false"
                  ></v-checkbox>
                </v-col>
                <v-col :cols="10" class="pb-2">
                  <car-info :shopInfo="item"></car-info>
                </v-col>
                <v-col :cols="1" class="pb-2 pl-0">
                  <v-icon size="22" class="cart_lock pointer" @click.stop="clickOrder(item,index)"  v-if="item.orderable == 1">
                    {{icons.mdiTruckCheckOutline}}
                  </v-icon>
                </v-col>

              </v-row>
            </template>
          </v-virtual-scroll>

        </div>
        <transition name="fade" mode="out-in">
          <v-progress-linear
              class="loading-liner"
              v-show="loading"
              color="success"
              buffer-value="0"
              stream
          ></v-progress-linear>
        </transition>


        <transition>
          <div
              class="box_bottomBtn flex rcenter between"
              v-show="selectInfo.count > 0"
          >

            <v-btn class=" font-weight-bold" height="2.5rem" x-small  :ripple="false" color="success" block :loading="isSell" @click="buySelectList">
              <div class="text-center">
                <span>SELL {{ selectInfo.count }} ITEM{{selectInfo.count>1?'S':''}} FOR </span>
                <span style="width: fit-content;background-color:#FFF" class="success--text px-1 rounded">${{ (selectInfo.price / 100) | matterPriceDecimal }}</span>
              </div>
            </v-btn>
<!--            <v-btn-->
<!--                class="bottomBtn_left"-->
<!--                @click="buySelectList"-->
<!--                v-ripple="{ center: true }"-->
<!--            >-->
<!--              <span v-if="!isSell">-->
<!--                SELL {{ selectInfo.count }}/{{buyLimit}} FOR ${{ selectInfo.price / 100 | matterPriceDecimal }}</span>-->
<!--              <v-progress-circular-->
<!--                  :value="20"-->
<!--                  indeterminate-->
<!--                  v-else-->
<!--                  :size="25"-->
<!--              ></v-progress-circular>-->
<!--            </v-btn>-->
          </div>
        </transition>
      </div>
    </div>

    <component :is="lazyComponents.cartOrder"
        v-if="showOrder"
        @close="showOrder=false"
        @success="orderSuccess"
        :shopInfo="showOrderItem"
    />

  </div>
</template>

<script>
import carInfo from "@/components/header/car/carInfo.vue";
import {sellCarts,  getCartPage0} from "@/api/cart";
import {debounce} from "@/utils/functions";
import { mdiTruckCheckOutline } from "@mdi/js"
export default {
  name: "carBox",
  props: ["device"],
  components: {
    carInfo,
  },
  data() {
    return {
      icons: { mdiTruckCheckOutline },
      lazyComponents: {
        cartOrder: null,
      },
      loading: false,
      buyLimit: 20,
      shopList: [],
      queryPage: {
        pageSize: 20,
        total: 0,
        id: null,
        userId: "",
        sortEnum: "NEWEST_FIRST",
      },
      total: "",
      maxPage: 0,

      oldList: [],
      selectedIds: [],
      bottomHop: 0,
      isSell: false,
      firstLoading: false,
    };
  },
  created () {
    if(this.$store.state.user.userId) {
      this.getCartList(false);
    }
  },
  mounted () {

  },
  computed: {
    dayStatus () {
      return this.$store.state.user.dark
    },
    selectInfo () {
      let count = { count: 0, price: 0 };
      let selectedList = this.shopList.filter(item => item.select) || []
      if (selectedList.length === 0) {
        return count
      }
      count.price = selectedList.reduce((a, b) => a + b.skuPrice, 0)
      count.count = selectedList.length
      return count;
    },
    showSelectAll() {
      return this.shopList.length || 0
    },
    selectAll: {
      get() {
        return this.shopList.length != 0 && !this.shopList.filter((item, index) => (index + 1) <= this.buyLimit).some(item => !item.select)
      },
      set(val) {
        if (val === false) {
          this.shopList.forEach(item => item.select = false)
        } else {
          this.shopList.filter((item, index) => (index + 1) <= this.buyLimit).forEach(item => item.select = true)
        }
      },
    },
  },
  methods: {
    orderSuccess(){
      this.hideSellItem(this.showOrderItem.id)
    },
    clickOrder(item){
      if(!this.lazyComponents.cartOrder){
        this.lazyComponents.cartOrder = ()=>import(`../../../views/user/inside/cart/cartOrder.vue`)
      }
      this.showOrder = true
      this.showOrderItem = item
    },
    toCart() {
      this.$router.push({path: "/userinfo", query: {nowShow: "cart"}});
      this.close();
    },
    onscroll(e) {
      if (this.queryPage.total === this.shopList.length) return;
      if (this.loading === true) return;
      this.top = e.scrollTop === 0;

      const visibleHeight = e.target.scrollHeight - e.target.clientHeight;

      if (e.target.scrollTop >= visibleHeight - 300) {
        this.getCartList()
      }
    },
    /**
     * 最后一个ID
     * @param nextPage
     */
    getCartList(nextPage = true) {
      let {total, ...params} = this.queryPage
      params.pageStart = nextPage?this.shopList.length:0
      this.loading = true;
      getCartPage0(params)
          .then((res) => {
            if (res.data && res.data.list) {
              let _list = res.data.list.map(item => {
                item.select = false
                return item
              });
              if (nextPage) {
                this.shopList = this.shopList.concat(_list)
              } else {
                this.shopList = _list || [];
              }
            }
            this.queryPage.total = res.data.total
            this.loading = false;

          })
          .finally(() => {
            this.loading = false;
          });
    },
    close() {
      this.$emit("close", "showCar");
    },
    //设置选择数据
    setSelectList(item, index) {
      this.shopList[index].select = !item.select
    },
    hideSellItem(id){
      let index = this.shopList.findIndex(item => item.id === id)
      this.shopList.splice(index, 1)
    },
    //卖出商品
    async buySelectList() {

      if (this.isSell) {
        return;
      }

      debounce(() => {
        this.loading = true
        this.isSell = true;
        let ids = this.shopList.filter(item => item.select).map(item => item.id) || []
        if (ids.length === 0) {
          return
        }
        sellCarts({ids}).then(res => {
          // 移除卖出的
          ids.forEach(id => {
            this.hideSellItem(id)
          })
          // 如果卖完了，再次获取
          if(this.shopList.length==0){
            this.getCartList(false)
          }
          this.queryPage.total -= ids.length
          this.$bus.$emit("updataUserinfo");
        }).finally(() => {

          this.isSell = false;
          this.loading = false;
        })
      }, 1000, true)

    },
  },
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s;
}

.v-enter {
  transform: translateY(100%);
}

.v-enter-to {
  transform: translateY(0);
}

.v-leave-to {
  transform: translateY(100%);
}

.cart_page {
  $sellBtnHeight: 2.5rem;
  .cart_page_box {
    transition: all 0.5s;
    width: 420px;
    min-width: 320PX;
    height: 31.25rem /* 500/16 */;
    box-shadow: 0px 3.9px 7.8px 0px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: $header-menu-height;
    right: 0px;
    z-index: 1500;
    box-sizing: border-box;
    padding: 16px 0 10px;
    font-size: 12px;
    overflow: hidden;
    background-color: var(--v-white_night-base);
    border-radius: 0 0 10px 10px;

    $sellDivPaddingb: 10px;
    $sellDivHeight: calc($sellBtnHeight + 10px + $sellDivPaddingb);

    .cart_page_box_main {
      width: 100%;
      height: 100%;

      .box_title {
        background-color: var(--v-grey_grey02-base);
        border-radius: 10px;
        min-height: 3.75rem /* 60/16 */;
        padding: 0 17.16PX;
        box-sizing: border-box;
        cursor: pointer;

        .title_left {
          width: auto;

          .title_img {
            width: 15.6PX;
            height: 15.6PX;
            margin-right: 10.92px;
            color: var(--v-black-base);
          }

          .cleft_info {
            span {
              color: var(--v-black-base);
            }

            .tit {
              font-weight: 700;
              font-size: 14.82px;
              //margin-bottom: 5px;
            }
          }
        }

        .title_right {
          .right_img {
            width: 14px;
            height: 14px;
          }
        }
      }

      .box_func {
        margin: 16px 0;

        .func_all,
        .func_not {
          padding: 10px;
          color: var(--v-carFBg-base);
          border: 1px solid var(--v-cec_c40-base);
          border-radius: 10px;
        }
      }

      .box_bottomBtn {
        width: 100%;

        padding: 10px 16px $sellDivPaddingb;


        height: $sellDivHeight;
        box-sizing: border-box;
        color: var(--v-white-base);
        font-size: 15px;
        font-family: Poppins;
        font-weight: 700;
        line-height: 16px;
        background-color: var(--v-white_grey02-base);
        position: absolute;
        bottom: 0;


        .bottomBtn_left,
        .bottomBtn_right {
          cursor: pointer;
          //width: 48%;
          height: 2.5rem;
          border-radius: 3.9px;
          padding: 10px;

          box-sizing: border-box;
          background-color: var(--v-btnBg-base);
          text-align: center;
          font-weight: 600;
        }
      }
    }
    .check_item_box {
      //margin: 0 16px;
      .check_item_box_scroll {
        //height: 21rem;
        height: calc(31.25rem - 8.5rem);
        padding-bottom: $sellDivHeight;
      }
    }
  }

  .cart_page_box_model {
    $topHeight: calc($header-menu-height + env(safe-area-inset-top, 0));

    $sellDivPaddingb: env(safe-area-inset-bottom,10px);
    $sellDivHeight: calc($sellBtnHeight + 10px + $sellDivPaddingb);

    top: $topHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - $topHeight);

    .box_bottomBtn {

      background-color: var(--v-btnFont-base) !important;
      // (bottom nav 60px + 20px) + padding:10px
      position: fixed!important;
      z-index: 0;
    }
    .box_top {
      height: 7.5rem /* 120/16 */;
    }
    .check_item_box {
      //margin: 0 16px;
      .check_item_box_scroll {
        height: calc(100vh - 7.5rem - 1rem - $topHeight);
        padding-bottom: $sellDivHeight;
      }
    }
  }

  .loading-liner {
    position: absolute;
    bottom: 2.5px;
    z-index: 1;
    @media (min-width: 100px) and (max-width: 600px) {
      position: fixed;
      bottom: 8px;
    }
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    background-color: transparent;
  }
}



.check_item {
  transition: all .4s;
  margin: 0;

}

.check_item:hover {
  background-color: rgba(0, 105, 219, 0.2);
}

.select_all_text {
  font-size: 12px;
  font-weight: 500;

}
</style>
