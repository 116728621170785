/** 解决 ios浏览器页面滚动到底部或顶部后 导致页面局部滑动失效的问题*/
export function iosTouchScroll () {
	let ios = navigator.userAgent.indexOf('iPhone'); // 判断是否为ios
	if (ios !== -1) { // ios下运行
		let el = document.querySelector('body'); // el -- 需要滑动的元素
		el.addEventListener('touchmove', function (e) {
			if (el.offsetHeight < el.scrollHeight)  e.isSCROLL = true;
		});
		document.body.addEventListener('touchmove', function (e) {
			if (!e.isSCROLL) e.preventDefault(); // 阻止默认事件(上下滑动)
		});
	}
}

/**
 * 获得验证码功能是否开启
 */
export function getCaptchaEnable() {
	if(import.meta.env.VITE_APP_CAPTCHA_ENABLE){
		return import.meta.env.VITE_APP_CAPTCHA_ENABLE === "true";
	}
	return false;
}
export function getImageUrl(path) {
	return new URL(path, import.meta.url).href

}
export function isMobile() {
	let _device = 'PC'
	let width = document.documentElement.clientWidth || document.body.clientWidth;
	if (width < 750) {
		_device = 'mobile'
	} 
	// let flag = navigator.userAgent.match(
	// 	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	// 	);
	// let mobile = 'PC';
	// if( flag ){
	// 	mobile = 'moblic';
	// }

	return mobile;
}

// 时间戳13位 转换Nov 8, 2022, 11:36:16 PM
export function formatTime(timen) {
	const monthEnglish = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec"];
	let month = monthEnglish[new Date(new Date(time)).getMonth()];
	let day = new Date(new Date(time)).getDate();
	let year = new Date(new Date(time)).getFullYear();
	let h = new Date(new Date(time)).getHours();
	let m = new Date(new Date(time)).getMinutes();
	let s = new Date(new Date(time)).getSeconds();
	let apm = 'AM';
	if (h > 12) {
		h -= 12;
		apm = 'PM'
	}
	if (h < 10) h = '0' + h;
	if (m < 10) m = '0' + m;
	if (s < 10) s = '0' + s;

	return (month + ' ' + day + ',' + year + ',' + h + ':' + m + ':' + s + ' ' + apm);
}

export function shareUrl() {
	let url = [
		'https://www.facebook.com/',//Facebook
		'https://www.twitter.com/',//twitter
		'http://instagram.com/',//ins
		'https://discord.com/',//discords
		'https://www.tiktok.com/',//douyin
		'https://www.youtube.com/',//youtube
		'https://www.twitch.tv/',//
	];
	return url;
}
