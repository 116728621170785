import store from '@/store'
const DEVICE_PC_FLAG = store.state.user.device === 'PC'

import router from '@/router'
import localforage from 'localforage'

/**
 * 滚动函数 兼容顶部的栏目导致偏移
 * @param e
 */
const scrollToHandler=(e)=>{

}
export const SHEPHERD_NAME_KEY_ENUM = {
    RECHARGE: "recharge",
    NEW_USER: "new_user"
}
/**
 * 手机端后缀加上 _mobile
 * key 小写 ${key}_mobile
 * @type {Map<any, any>}
 */
const SHEPHERD_NAME_MAP = new Map()

// 第一步：开盒页面高亮开盒按钮；2，高亮顶边栏充值框，3.充值页面分步高亮两个按钮 4.高亮顶边栏对战菜单按钮 5.高亮创建对战按钮
const RECHARGE_STEP_MAP = new Map()
const NEW_USER_MAP = new Map()
// 充值步骤
SHEPHERD_NAME_MAP.set('recharge', RECHARGE_STEP_MAP)
SHEPHERD_NAME_MAP.set(SHEPHERD_NAME_KEY_ENUM.NEW_USER, NEW_USER_MAP)
//

const SHEPHERD_NAME_STATUS_CACHE_KEY = "SHEPHERD_STEP_LIST"
/**
 * status: 0 没有完成
 * status: 1 已经完成
 * @type {[{name: string, status: number},{name: string, status: number}]}
 */
const SHEPHERD_NAME_STATUS_LIST = [
    {
        name: SHEPHERD_NAME_KEY_ENUM.RECHARGE,
        status: 0
    },
    {
        name: SHEPHERD_NAME_KEY_ENUM.NEW_USER,
        status: 0
    }
]

export async function getShepherdList() {
    return await localforage.getItem(SHEPHERD_NAME_STATUS_CACHE_KEY) || []
}

export function setShepherdList(shepherdList) {
    localforage.setItem(SHEPHERD_NAME_STATUS_CACHE_KEY, shepherdList)
}

/**
 * done shepherdName
 * @param key 参照router name 或其他
 */
export async function skipShepherd(shepherdName) {
    let shepherdList = await getShepherdList() || []
    let index = shepherdList.findIndex(({name}) => name === shepherdName)
    if (index != -1) {
        shepherdList[index].status = 1
        setShepherdList(shepherdList)
    }
    store.dispatch('user/changeShepherdName', '')
}

/**
 * 链路: startShepherd('recharge') => getShepherdStepByName('router name') => judgmentSkipShepherd('recharge') => 渲染
 * 中途结束/完成: skipShepherd('recharge') / vuex => changeShepherdName=''
 *
 */
export function startShepherdRecharge() {
    store.dispatch('user/changeShepherdName', SHEPHERD_NAME_KEY_ENUM.RECHARGE)
}

export function startShepherdNewUser() {
    store.dispatch('user/changeShepherdName', SHEPHERD_NAME_KEY_ENUM.NEW_USER)
}


/**
 *
 * 判断是否已经跳过这个引导，如果没有就就行初始化
 * status: 0 没有完成
 * status: 1 已经完成
 * @param shepherdName 名称
 * @returns {boolean}
 */
export async function judgmentSkipShepherd(shepherdName) {
    let shepherdList = await getShepherdList() || []
    let item = shepherdList.find(({name}) => name === shepherdName)
    if (item) {
        return Number(item.status) === 1
    }
    // 没有，判断是否存在，就行初始化
    else {
        let newItem = SHEPHERD_NAME_STATUS_LIST.find(({name}) => name === shepherdName)
        if (newItem) {
            shepherdList.push(newItem)
            setShepherdList(shepherdList)
            return false
        }
    }
}
export async function judgmentShepherdNewUser() {
    return judgmentSkipShepherd(SHEPHERD_NAME_KEY_ENUM.NEW_USER)
}

/**
 * 考虑加上手机端支持
 * 获取对应的key
 * @param name
 * @returns {any|*[]}
 */
export async function getShepherdStepByName(name) {
    let shepherdName = store.state.user.shepherdName
    if ((shepherdName ?? '') === '') {
        return []
    }
    let flag = await judgmentSkipShepherd(shepherdName)
    if (flag) return []
    let stepMap = SHEPHERD_NAME_MAP.get(shepherdName)
    if (stepMap) {
        if (!name) return []
        let k = name.toLowerCase()
        return stepMap.get(k) || []
    }
    return []
}


NEW_USER_MAP.set("boxinfo", [
    {
        attachTo: {
            element: '.shopPrice_leftBtn',
            on: 'bottom',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: "I'm fine on my own.",
            },
            {
                action: async function () {
                    return this.next();
                },
                text: 'Show me around!',
            },
        ],
        text: 'How would you like a 5-step guide to get started?',
    },
    {
        attachTo: {
            element: '.moeny_button',
            on: 'top',
        },
        buttons: [
            {
                action: function () {
                    return this.back();
                },
                secondary: true,
                text: 'Back',
            },
            {
                action: function () {
                    let rechargeDom = document.querySelector('.moeny_button')
                    if (rechargeDom) {
                        rechargeDom.click()
                    }
                    return this.next();
                },
                text: 'Fill me up!',
            },
        ],
        text: 'This is where you recharge',
    },
])
//
NEW_USER_MAP.set("recharge", [
    {
        attachTo: {
            element: '.count-down-card-panel-btn',
            on: 'top',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: 'Exit',
            },
            {
                action: function () {
                    let dom = document.querySelector('.recharge_btn_countdown')
                    if (dom) {
                        dom.click()
                    }
                    return this.next();
                },
                text: 'Click this to continue',
            },
        ],
        text: 'Keep going!',
    },
    {
        attachTo: {
            element: '.count-down-card-panel-btn',
            on: 'top',
        },
        buttons: [
            {
                action: function () {
                    return this.back();
                },
                secondary: true,
                text: 'Back',
            },
            {
                action: function () {
                    // 3. 充值完回到开盒详情页(跳转一个默认盒子,这个盒子的id在管理后台配置)
                    // https://k29t9oz5u6.feishu.cn/docx/S0x5dZPGNoNk5QxiKmNcnM3pnjf
                    let dom = document.querySelector('.recharge_btn_deposit')
                    if (dom) {
                        dom.click()
                    }
                    return this.next();
                },
                text: 'Show me the money!',
            },
        ],
        text: 'Wait for the countdown to end, then you will receive the deposit.',
    },
    {
        attachTo: {
            // 手机端
            element: DEVICE_PC_FLAG ? '#CLASHES' : '#nav_clash',
            on: DEVICE_PC_FLAG?'bottom':'top',
        },
        buttons: [
            {
                action: function () {
                    return this.back();
                },
                secondary: true,
                text: 'Back',
            },
            {
                action: function () {

                    router.push({path: "/clash"})

                    return this.next();
                },
                text: 'GO CLASHES',
            },
        ],
        text: `Let's try it`,
    },
])
NEW_USER_MAP.set("clash", [
    {
        attachTo: {
            element: '#clash_area',
            on: DEVICE_PC_FLAG ? 'top' : 'top',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: 'Exit',
                // action: function () {
                //     return this.cancel();
                // },
                // secondary: true,
                // text: 'Exit',
            },
            {
                action: async function () {

                    // skipShepherd(SHEPHERD_NAME_KEY_ENUM.RECHARGE)
                    return this.next();
                },
                text: 'Create Clash',
            },
        ],
        text: '这里是对战区域',
    }, {
        attachTo: {
            element: '#clash_btn_create',
            on: 'top',
        },
        buttons: [
            {
                action: function () {
                    return this.back();
                },
                secondary: true,
                text: 'Back',
                // action: function () {
                //     return this.cancel();
                // },
                // secondary: true,
                // text: 'Exit',
            },
            {
                action: async function () {
                    let dom = document.querySelector('#clash_btn_create')
                    if (dom) {
                        dom.click()
                    }
                    // skipShepherd(SHEPHERD_NAME_KEY_ENUM.RECHARGE)
                    return this.next();
                },
                text: 'Create Clash',
            },
        ],
        text: '来创建你的对战',
    },
])
/**
 * 创建对战页面
 */
let clash_create_list = []

clash_create_list.push(
)

// 6. 圈住导航栏个人中心部分
if (DEVICE_PC_FLAG) {
    clash_create_list.push(
    )
}

NEW_USER_MAP.set("create", [
    // 选中clash按钮
    {
        attachTo: {
            element: '#clash_create_btn_add',
            on: DEVICE_PC_FLAG ? 'top' : 'top',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: 'Exit',
            },
            {
                action: async function () {
                    return this.next();
                },
                text: '挑选盒子',
            },
        ],
        text: '打开宝藏盒',
    },
    // 这里H5没有知道好友的 H5换成打开个人中心
    {
        attachTo: {
            element: DEVICE_PC_FLAG ? '#header-friend' : '#nav_userinfo',
            on: 'top',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: 'Exit',
            },
            {
                action: async function () {
                    if (DEVICE_PC_FLAG) {

                        //   1. 圈住好友部分
                        //   2. 自动跳转333的好友页面
                        //   3. 圈住加好友按钮,引导用户加好友
                        router.push({path: "/u/333?"})
                    }
                    // 打开个人中心
                    else {

                        router.push({path: "/userinfo"})
                    }
                    // skipShepherd(SHEPHERD_NAME_KEY_ENUM.RECHARGE)
                    return this.next();
                },
                text: DEVICE_PC_FLAG ? '让我来添加一个好友吧' : '打开个人中心',
            },
        ],
        text: DEVICE_PC_FLAG ? '这里是你的好友列表' : '让我们去找你的好友列表',
    }
])
// H5 个人中心 -> 介绍好友列表
if (!DEVICE_PC_FLAG) {
    NEW_USER_MAP.set("userinfo", [
        {
            attachTo: {
                element: '#userinfo_friend',
                on: 'bottom',
            },
            buttons: [
                {
                    action: function () {
                        return this.cancel();
                    },
                    secondary: true,
                    text: 'Exit',
                },
                {
                    action: async function () {
                        router.push({path: "/u/333"})
                        return this.next();
                    },
                    text: '让我来添加一个好友吧',
                },
            ],
            text: '这里是你的好友列表',
        }
    ])
}


/**
 * public-user
 * 引导添加好友
 */
NEW_USER_MAP.set("public-user", [

    //   3. 圈住加好友按钮,引导用户加好友
    {
        attachTo: {
            element: '#friend_info_btn',
            on: DEVICE_PC_FLAG ? 'bottom' : 'bottom',
        },
        buttons: [
            {
                action: function () {
                    return this.back();
                },
                secondary: true,
                text: 'Back',
            },
            {
                action: async function () {

                    // friend_status_invite

                    // 点击添加好友
                    let dom = document.querySelector('#friend_status_invite')
                    if (dom) {
                        dom.click()
                    }
                    // 结束新用户引导
                    skipShepherd(SHEPHERD_NAME_KEY_ENUM.NEW_USER)
                    return this.next();
                },
                text: '添加为好友',
            },
        ],
        text: '让我们添加一个好友吧',
    },
])

NEW_USER_MAP.set("home", [
    {
        text: 'featured boxes',
        attachTo: {
            element: '#index_boxList',
            on: 'bottom',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: 'Exit',
            },
            {
                action: async function () {

                    return this.next();
                },
                text: 'latest clashes',
            },
        ],
    },
    {
        text: 'latest clashes',
        attachTo: {
            element: '#index_battleList',
            on: 'top',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: 'Exit',
            },
            {
                action: async function () {
                    return this.next();
                },
                text: 'Create Clash',
            },
        ],
    },
    {
        text: 'live drop',
        attachTo: {
            element: '#live_drops',
            on: DEVICE_PC_FLAG ? 'left' : 'bottom',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: 'Exit',
            },
            {
                action: async function () {
                    return this.next();
                },
                text: 'To Chat',
            },
        ],
    },
    {
        text: 'Chat',
        attachTo: {
            element: '#header-pchat',
            on: 'bottom',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: 'Exit',
            },
            {
                action: async function () {
                    let dom = document.querySelector('#header-pchat')
                    if (dom) {
                        dom.click()
                    }
                    return this.next();
                },
                text: 'jump Recharge Btn',
            },
        ],
    },
    {
        text: 'Recharge',
        attachTo: {
            element: '#header-plus',
            on: 'bottom',
        },
        buttons: [
            {
                action: function () {
                    return this.cancel();
                },
                secondary: true,
                text: 'Exit',
            },
            {
                action: async function () {
                    let rechargeDom = document.querySelector('.moeny_button')
                    if (rechargeDom) {
                        rechargeDom.click()
                    }
                    return this.next();
                },
                text: 'Go Recharge',
            },
        ],
    },

])
