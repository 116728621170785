import Vue from 'vue'
import VueAWN from "vue-awesome-notifications"
import { mdiPartyPopper,mdiAlertBoxOutline,mdiCommentAlertOutline,mdiCog,mdiHelp    } from "@mdi/js"
// https://f3oall.github.io/awesome-notifications/docs/customization/
//弹窗消息提示的样式
let awsOptions = {
    position: 'top-left',
    labels: {
        success: 'YAY',
        alert: 'Opps',
        warning: 'Oh no',
        info: 'Heads up'
    },

/*

  #Google Icons (Material icons)
  prefix: "<i class='material-icon'>",
  sucess: "face",
  suffix: "</i>",
  #img
  prefix: "<img src='",
  success: "https://www.examplepath.com/yourimg.svg",
  suffix: "'/>",
* */
    icons:{
        success: mdiPartyPopper ,
        info: "assistant",
        warning: mdiAlertBoxOutline ,
        // 图标问题 error_med会错位
        // warning: "alert-circle",
        alert: mdiCommentAlertOutline,
        async: mdiCog ,
        confirm: mdiHelp,
        prefix: `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="v-icon__svg"><path d="`,
        suffix: `"/></svg>`,
    },

    durations: {
        success: 1000,
        warning: 3000,
        alert: 3000,
        error: 2000,
        info: 2000},
}
Vue.use(VueAWN, awsOptions)
