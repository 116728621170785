<template>
  <div class="footer_bannerList relative">

    <swiper ref="swiper" class="footer_banner" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in configList.introductionBarList" :key="index">
        
        
        <!-- <v-img class="pointer footer_banner-image" @click="clickBanner(item, index)"
          :src="$S3Utils.toWebp(item.imageUrl)"></v-img> -->

          <div v-lazy-container="{ selector: 'img' }" flex items-center justify-center>
            <img
            :data-src="$S3Utils.toWebp(item.imageUrl)" 
            :data-loading="$S3Utils.resizeGoodsPictureLazy(item.imageUrl)" 
            class="pointer footer_banner-image h-full w-full" @click="clickBanner(item, index)"
            alt="onlibox">
          </div>

      </swiper-slide>

      <!-- <div class="swiper-button-prev elevation-1 hover-scale" slot="button-next">
        <v-icon color="white" class="" size="16">{{ icons.mdiChevronLeft }}</v-icon>
      </div>
      <div class="swiper-button-next elevation-1 hover-scale" slot="button-next">
        <v-icon color="white" class="" size="16">{{ icons.mdiChevronRight }}</v-icon>
      </div> -->


    </swiper>

  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css' // 注意这里的引入


import { mdiCircle, mdiChevronLeft, mdiChevronRight } from "@mdi/js"

export default {
  name: "IndexBannerArea",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    configList: {
      type: Object,
      default: () => {
      }
    },
    device: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    icons: {
      mdiCircle, mdiChevronLeft, mdiChevronRight
    },
  }),
  computed: {
    swiperOptions ({ device }) {
      let slidesPerView = 1
      if (device == 'PC') slidesPerView = 3
      console.log({ slidesPerView })
      return {
        freeMode: true,
        // 衔接滑动
        loop: false,
        spaceBetween: 0,
        slidesPerView,
        slideToClickedSlide: true,
        // 使用前进后退按钮来控制Swiper切换。
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // pagination: {
        //   el: '.swiper-pagination', //与slot="pagination"处 class 一致
        //   clickable: true,
        //   type: 'fraction',
        // },
      }
    },
  },
  mounted () {
  },
  methods: {

    clickBanner (item, index) {
      
      let _www = window.location.origin.includes('www') ? 'www.' : ''
      let _url = ''
      if (index == 0) {
        _url =  `https://${_www}onlibox-lite.com`
      } else if (index == 1) {
        _url = `https://${_www}onlibox.com`
      } else if (index == 2) {
        _url = `https://${_www}onlibox.com/box/onlifans`
      }
      window.open(_url)
    },

  },
}
</script>

<style lang="scss" scoped>
.footer_bannerList {
  overflow: hidden;
  padding: 4rem 0 0 0;
}

.footer_banner-image {
}

/* 自定义导航按钮样式 */

@media (max-width:1200px) {
  .footer_bannerList {
    overflow: hidden;
    padding: 1rem 0 0 0;
  }


}
</style>
